@import 'src/styles/base';

.Container {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    row-gap: $spacer05;

    & .Header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        & .Info {
            &:after {
                content: ':';
            }
        }

        & .OrganizationName {
            display: flex;
            flex: 1;
            @include font-Poppins-Regular;
            font-size: 1.4rem;
            line-height: 2.2rem;
            color: var(--text-dark);
            word-break: keep-all;
            text-align: start;
            font-weight: 600;
        }

        & .OrganizationLogo {
            margin-left: 1.5rem;
            height: 6rem;
            width: 6rem;
            flex-shrink: 0;
            object-fit: contain;
            object-position: center;
        }
    }

    & .Body {
        align-self: flex-start;
    }

    & .Button {
        width: 100%;
        margin-top: $spacer05;

        @include media('>tablet') {
            width: fit-content;
        }
    }
}
