@import 'src/styles/base';

.Navbar {
    background-color: var(--primary);
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    bottom: 0;
    display: flex;
    min-height: 5.8rem;
    position: fixed;
    width: 100%;
    flex-direction: column;

    @include media('>tablet') {
        bottom: initial;
        top: 0;
    }
}

.ContentContainer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 123rem;
    margin: 0 auto;
    box-shadow: 0 5px 9px -3px rgba(var(--text-dark-rgb), 0.26);
    &.vg {
        box-shadow: none;
    }
}

.NavbarLogoContainer {
    display: none;
    width: 100%;
    padding: 1.6rem;
    padding-bottom: 0;
    align-items: center;
    justify-content: space-between;

    @include media('>tablet') {
        display: flex;
    }
}
.NavbarLogoContainer.vg {
    padding: 1.2rem 1.6rem;
    background-color: $vg-dark-header;
}
.NavbarLogo {
    display: flex;
    align-items: flex-end;
    font-family: $vg-font-secondary;
    max-height: 28px;
    cursor: pointer;
}

.VGLogo {
    max-width: 90px;
}

.NavbarAdditionalText.vg {
    font-size: 12pt;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: $white;
}

.SecondAdditionalText {
    font-size: 10pt;
}

.MenuContainer {
    display: flex;
    align-items: center;
}

.NavbarLinksContainer {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex: 1 1 0;
}

.NavbarLinksContainer.vg {
    display: flex;

    @include media('>tablet') {
        max-height: 64px;
    }
}

.NavbarLinks {
    display: flex;
    width: 100%;
    @include media('>desktop') {
        padding: 0 1rem;
    }
}

.SingleLink {
    justify-content: flex-end;
    @include media('>tablet') {
        a:only-child {
            flex: unset;
        }
    }
}
