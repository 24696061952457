@import 'src/styles/base';

.Headline {
    @include font-Poppins-Medium;
    color: var(--third-dark);
    font-size: 1.8rem;
    margin: 0;
}

.marginBottom {
    margin-bottom: 1em;
}
