@import '../../../styles/colors';
@import '../../../styles/rwd';

.FormContainer {
    align-items: center;
    display: flex;
    flex-direction: column;
}

.FileUplaoderHint {
    color: var(--text-dark);
    display: none;
    font-weight: 500;
    margin: 2rem 0;
    max-width: 90%;
    text-align: center;

    @include media('>tablet') {
        display: block;
        max-width: 60%;
    }
}

.FileUplaoderHintMobile {
    display: block;

    @include media('>tablet') {
        display: none;
    }
}
