@import 'src/styles/base';

.ToCeremonyContainer {
    cursor: pointer;
}

.ToCeremonyText {
    @include font-Poppins-Regular;
    margin: 0.5rem 0;
    @include media('>tablet') {
        margin: 1rem 0;
    }
}

.ButtonLink {
    margin: 5px auto;
    color: var(--third);
}
