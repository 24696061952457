@import 'src/styles/base';

.Footer {
    @include font-Poppins-Medium;
    font-size: 1.2rem;
    background-color: rgba(var(--secondary-dark-rgb), 0.5);
    height: 16.25rem;

    justify-content: space-between;
    width: 100%;
    user-select: none;

    @include media('>tablet') {
        height: auto;
    }

    @include media('>desktop') {
        font-size: 1.4rem;
    }
}

.Footer.vg {
    background-color: $vg-dark-header;
    font-size: 14px;

    @include media('>tablet') {
        background-color: var(--primary);
        font-size: 18px;
    }
    @include media('>tablet', '<desktop') {
        font-size: 15px;
    }
}

.Container {
    height: 9rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 2rem;
    padding-right: 2rem;
    max-width: 127rem;
}

.Copyright {
    flex: 1;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    padding: $spacer05 0;
    margin-right: $spacer05;

    @include media('>tablet') {
        flex-direction: row;

        & :first-child {
            white-space: nowrap;
            &::after {
                content: '\00a0 ';
            }
        }
    }
}

.LinksAndReport {
    @include centered;
    justify-content: flex-end;
    @include media('>tablet') {
        flex: 1;
    }
    &.ReportOnly {
        justify-content: flex-end;
    }
}

.Link,
.ViolationReport {
    padding: $spacer05;
    white-space: nowrap;
    text-align: center;
    @include media('>tablet') {
        padding: $spacer075;
    }
}

.Copyright,
.Link,
.ViolationReport {
    @include hoverable;
    color: rgba(var(--primary-rgb), 0.8);
}

.Copyright.vg,
.Link.vg,
.ViolationReport.vg {
    color: $white;
}

.Link {
    display: none;

    @include media('>tablet') {
        display: block;
    }
}

.ViolationReport {
    margin-right: -$spacer05;
}

.ButtonsContainer {
    margin-top: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 3rem;

    @include media('>tablet') {
        margin: 1rem 0;
    }
}
