@import '../../../styles/rwd';
@import '../../../styles/colors';

.Modal {
    overflow-y: auto;
    border: 1px solid rgba(var(--secondary-dark-rgb), 0.6);
}

.RemovalLabel {
    color: var(--text-dark);
    font-weight: 500;
    margin: 2rem 0;
    text-align: center;
    display: block;
}

.ButtonsContainer {
    margin-top: 5rem;
    display: flex;
    justify-content: center;
    button {
        margin-left: 1rem;
    }
}
