.videoStream {
    position: relative;
    overflow: hidden;
    display: block;
    padding: 30px;
}

.iframeWrapper {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 75%;
}

.iframe {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    outline: none;
}
