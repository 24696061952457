@import 'src/styles/base';

.ImageContainer {
    position: relative;
    &.ImageContainerWithBlurredBackground {
        background: $white;
    }
}

.Image {
    &.ImageHidden {
        visibility: hidden;
    }
    &.ImageWithBlurredBackgroundBlurred {
        object-fit: cover !important;
        filter: blur(15px) opacity(0.9);
    }
    &.ImageWithBlurredBackgroundFocused {
        position: absolute;
        top: 0;
        left: 0;
        object-fit: contain !important;
    }
}
