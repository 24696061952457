@import '../../../styles/colors';
@import '../../../styles/rwd';

.SectionHeader {
    text-align: center;
}

.SectionDescription {
    color: var(--text-dark);
    margin: 0 auto;
    padding: 0 3.2rem;
    text-align: center;

    @include media('>tablet') {
        max-width: 70%;
        padding: 0;
    }
}

.ShareUrl {
    padding: 0 2rem;

    @include media('>tablet') {
        margin: 0 auto;
        max-width: 60%;
        padding: 0;
    }
}
