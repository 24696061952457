@import 'src/styles/base';

.FlowersTypeItems {
    display: grid;
    margin: 0 auto;
    grid-column-gap: 1.6rem;
    grid-row-gap: 3.2rem;

    @include media('<tablet') {
        grid-template-columns: 1fr;
    }

    @include media('>tablet') {
        grid-template-columns: repeat(2, calc(100% / 2));
    }

    @include media('>desktop') {
        grid-template-columns: repeat(3, calc(100% / 3));
    }
}

.FlowersCategoryBox {
    display: flex;
    margin-bottom: 1.5rem;
    margin-left: 1rem;
}

.FlowersCategoryImage {
    height: 6.4rem;
    width: 6.4rem;
    object-fit: cover;
}

.FlowersCategoryName {
    @include font-Poppins-Medium;
    color: var(--text-dark);
    display: block;
    font-size: 1.6rem;
    margin-left: 1.6rem;
}

.FlowersCategoryButtonLink {
    @include font-Poppins-Regular;
    color: var(--third-dark);
    font-size: 1.6rem;
    margin-bottom: 1.5rem;
    text-decoration: none;
}

.FlowersCategoryButtonLinkIcon {
    height: 1.2rem;
    margin-right: 1rem;
}

.BackButton {
    margin: 2rem 0 3rem 1rem;

    @include media('>tablet') {
        margin-bottom: 0;
    }
}
