@import 'src/styles/base';

.Section {
    margin: 3.2rem 0;
}

.Container {
    display: flex;
    flex-direction: column;
    padding: 3rem 2rem 1rem;

    @include media('>tablet') {
        align-items: center;
        padding: 3rem 0 1rem;
    }
}

.Headline {
    color: var(--primary);
    font-size: 1.8rem;
    font-weight: bold;
    margin-bottom: 3.2rem;
    text-align: center;

    @include media('>tablet') {
        font-size: 2.6rem;
    }
}

.Message {
    @include font-Poppins-Medium;
    color: $valencia;
    font-size: 1.6rem;
    margin-bottom: 3.5rem;

    @include media('>tablet') {
        font-size: 1.8rem;
    }
}

.DetailsSentTo {
    &::after {
        content: ': ';
    }
}

.Button {
    width: 100%;

    @include media('>tablet') {
        width: initial;
    }
}
