@import 'src/styles/base';

.ListRow {
    display: flex;
    color: var(--text-dark);
    margin-bottom: 1.5rem;
}

.IconContainer {
    width: 3.2rem;
    margin-right: 2rem;
    background: rgba(var(--secondary-dark-rgb), 0.2);
    border-radius: 50%;
    height: 3.2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 0 0 3.2rem;
}

.Icon {
    width: 2rem;
    height: 2rem;
    color: var(--text-dark);
}

.RowContent {
    @include font-Poppins-Regular;
    margin: 0.5rem 0;
    align-self: center;
    word-break: break-word;
}
