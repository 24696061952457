@import 'src/styles/base';

.MaskContainer {
    @include centered;
    display: flex;
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 1;
    background: rgba($black, 0.5);
    cursor: default;
    &.ReplayMaskContainer {
        & .MaskInnerContainer {
            & .MaskIcon {
                svg {
                    width: 6.5rem;
                    height: 6.5rem;
                }
            }
        }
    }
    & .MaskInnerContainer {
        & .MaskIcon {
            border: 0;
            outline: 0;
            opacity: 1 !important;
            cursor: default;
            svg {
                width: 7rem;
                height: 7rem;
                color: $white;
                filter: drop-shadow(0 0 1px $white);
            }
        }
        & .MaskText {
            color: $white;
            font-size: 1.25rem;
            font-weight: 400;
            white-space: nowrap;
            line-height: 1.75rem;
        }
    }
}
