@mixin section-border {
    border-radius: 3px;
    border: 1px solid rgba(var(--secondary-dark-rgb), 0.6);
}

@mixin section-separator-bar-top {
    border-top: 1px solid rgba(var(--secondary-dark-rgb), 0.6);
}
@mixin section-separator-bar-bottom {
    border-bottom: 1px solid rgba(var(--secondary-dark-rgb), 0.6);
}

@mixin dashed-border {
    border: 1px dashed var(--secondary-dark);
    border-radius: 3px;
}
