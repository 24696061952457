@import 'src/styles/base';

.Form {
    width: 100%;
}

.FieldsContainer {
    display: flex;
    margin-bottom: 2rem;
    gap: 2rem;
    & :first-child {
        flex: 0.7;
    }
    & :nth-child(2) {
        flex: 0.3;
    }
}

.ButtonContainer {
    display: flex;
    justify-content: flex-end;
    margin-top: 2rem;
}

.Button {
    width: 100%;

    @include media('>tablet') {
        width: auto;
    }
}
