@import '../../../styles/rwd';
@import '../../../styles/colors';

.Form {
    margin: 4rem 2rem 8rem;

    @include media('>tablet') {
        margin: 4rem auto 10rem;
        max-width: 60rem;
    }
}

.LoginButton {
    margin: 0 auto;
}

.StatusMessage {
    color: var(--primary);
    margin-bottom: 4rem;
    font-weight: 700;
}
