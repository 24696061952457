@import 'src/styles/base';

.Modal {
    overflow-y: auto;
    border: 1px solid rgba(var(--secondary-dark-rgb), 0.6);
}

.RemovalLabel {
    color: var(--text-dark);
    font-weight: 500;
    margin: 2rem 0;
    text-align: center;
    display: block;
}

.ButtonsContainer {
    margin-top: $spacer1;
    display: flex;
    justify-content: center;
}

.SignatureField {
    margin-top: $spacer05;
}
