@import 'src/styles/base';

.Container {
    display: flex;
    flex-direction: column;
}

.Tabs {
    margin-top: 2rem;
    margin-bottom: 2rem;
    @include media('>=tablet') {
        margin-top: 3rem;
        margin-bottom: 5rem;
    }
}

.Flower {
    position: relative;
    display: grid;
    align-items: center;
    grid-template-columns: 32% 1fr;
    grid-template-rows: 1fr;
    gap: 4rem;
    margin-bottom: 4rem;
    min-height: 25rem;
    @include media('<tablet') {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 25rem;
    }
    & .Info {
        display: flex;
        flex-direction: column;
        gap: 1.25rem;
        @include media('<tablet') {
            order: -1;
        }
        .Name {
            @include font-Poppins-Medium;
            font-size: 1.8rem;
            font-weight: 600;
        }
    }
    & .ImagePortal {
        align-self: center;
        position: absolute;
        left: 32%;
        height: 25rem;
        width: 68%;
    }
}

.PlaceholderPhoto {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.Buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 4rem;
}
