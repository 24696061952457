@import '../../../../styles/rwd';
@import '../../../../styles/colors';

.ShareButton {
    margin-bottom: 1rem;

    @include media('>tablet') {
        margin-bottom: 0;
    }
}

.SecondShare {
    margin-left: 1rem;
}

.ShareApiError {
    overflow: hidden;
    border: 1px solid var(--error);
}
