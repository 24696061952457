@import 'src/styles/base';

.Container {
    padding: 1.5rem;
    position: relative;

    @include media('>tablet') {
        padding: 20rem 0 8rem;
        margin-left: -5rem;
        margin-right: -5rem;
        margin-top: -7rem;
        margin-bottom: 0;
    }
}

.ContentContainer {
    max-width: 123rem;
    width: 100%;
    left: 50%;
    transform: translateX(-50%);
    position: absolute;
    top: 0;
    display: none;

    @include media('>tablet') {
        display: block;
    }
}

.ContentContainerMobile {
    display: block;

    @include media('>tablet') {
        display: none;
    }
}

.Background {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    overflow: hidden;
    width: 100vw;
    z-index: -1;

    @include media('>tablet') {
        left: -2rem;
        right: -2rem;
        width: 100%;
    }

    img {
        z-index: -1;
        position: relative;
        height: 100%;
        object-fit: cover;
    }
}

.InnerContainer {
    background: rgba($white, 0.8);
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 2rem;
    box-shadow: 0 3px 40px -5px rgba(var(--shadow-rgb), 0.79);
    position: relative;
    margin-top: 9rem;
    flex-wrap: wrap;
    border-radius: 10px;
    padding-bottom: 0;

    @include media('>tablet') {
        flex-wrap: nowrap;
        height: 16rem;
        margin-top: 0;
        padding: 0 10%;
        align-items: center;
    }
}

.InnerContainer.vg {
    background-color: #ffffffb3;
}

.Wrapper {
    @include media('>tablet') {
        padding: 0 10rem;
    }
}

.Name {
    @include media('<=tablet') {
        flex-basis: calc(100% + 4rem);
        width: calc(100% + 4rem);
        max-width: calc(100% + 4rem);
        min-width: 100%;
        margin-left: -2rem;
        margin-right: -2rem;
        margin-top: 2rem;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }

    @include media('>tablet') {
        position: absolute;
        top: 14rem;
        left: 50%;
        transform: translateX(-50%);
        z-index: 10;
        margin-top: 0;
    }
}

.Name.vg {
    background-color: var(--primary);
    color: $white;
    text-transform: uppercase;
    font-weight: 400;

    @include media('>tablet') {
        border-radius: 0;
        font-size: 18pt;
    }
}

.Avatar {
    width: 10rem;
    height: 10rem;
    position: absolute;
    top: -4.5rem;
    left: 50%;
    transform: translateX(-50%);

    @include media('>tablet') {
        width: 25rem;
        height: 25rem;
        top: -12rem;
        z-index: 0;
    }
}

.AvatarPlaceholder {
    align-items: center;
    background-color: var(--secondary);
    border-radius: 50%;
    display: flex;
    justify-content: center;
}

.AvatarPlaceholderIcon {
    color: var(--primary);
}

.TopLine {
    position: absolute;
    left: 0;
    top: 1rem;

    display: none;

    @include media('>tablet') {
        display: block;
    }
}

.BottomLine {
    position: absolute;
    left: 0;
    bottom: 1rem;

    display: none;

    @include media('>tablet') {
        display: block;
    }
}

.ButtonsLanguageContainer {
    position: absolute;
    top: 2rem;
    z-index: 1;
    display: flex;
    flex-direction: column;
    padding-right: 1rem;

    @include media('>tablet') {
        left: 5rem;
        top: 10rem;
        order: 1;
        padding-right: 0;
    }
}
.VGLogoContainer {
    padding-top: 0.5rem;
    position: relative;
    height: 20px;

    @include media('>tablet') {
        display: none;
    }
}

.VGLogo {
    position: absolute;
    z-index: 1;
}

.ButtonsLanguageContainer.vg {
    top: 5rem;

    @include media('>tablet') {
        top: 10rem;
        flex-direction: column;
        width: auto;
    }
}

.CompanyContainer {
    position: absolute;
    top: 2.5rem;
    right: 1.5rem;
    z-index: 1;

    @include media('>tablet') {
        right: 5rem;
        top: 10rem;
    }
}

.CompanyContainer.Admin {
    top: 2rem;

    @include media('>tablet') {
        right: 5rem;
        top: 10rem;
    }
}

.ButtonsContainer {
    display: flex;
    align-items: center;
}

.EditMPButton {
    order: 2;
    margin-top: -0.75rem;
    z-index: 1;
    width: min-content;
    min-width: calc(100% - 1.25rem);
    & button {
        @include hoverable;
        @include font-Poppins-Medium;
        background-color: $white;
        border: 1px solid var(--secondary-dark);
        border-radius: 3px;
        color: var(--text-dark);
        font-size: 1.4rem;
        font-weight: 500;
        outline: 0;
        padding: 0.5rem 1rem;
        width: 100%;
        &.vg {
            @include font-Merriweather-Medium;
        }
        &.Mobile {
            display: block;
        }
        &.Desktop {
            display: none;
        }
    }
    @include media('>tablet') {
        margin-top: 1rem;
        order: 2;
        min-width: 100%;
        width: max-content;
        & button {
            padding: 0.25rem 1.4rem;
            min-width: unset;
            &.Mobile {
                display: none;
            }
            &.Desktop {
                display: block;
            }
        }
    }
    @include media('<400px') {
        min-width: unset;
    }
}

.ShareApiError {
    overflow: hidden;
    border: 1px solid var(--error);
}
