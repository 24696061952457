@import 'src/styles/base';

.Container {
    display: flex;
    flex-direction: column;
}

.InputBox {
    width: 100%;
    display: flex;
    align-items: center;
}

.Input {
    border: 1px solid var(--secondary-dark);
    border-bottom-left-radius: 3px;
    border-right: 0;
    border-top-left-radius: 3px;
    color: var(--text-dark);
    font-size: 1.2rem;
    height: 2.8rem;
    min-width: 30%;
    outline: 0;
    padding: 0 0.8rem;

    &::-webkit-input-placeholder {
        color: var(--text-dark);
    }
}

.Button {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
}

.Error {
    display: block;
    font-size: 1.2rem;
    margin-top: 0.5rem;
}
