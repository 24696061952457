@import 'src/styles/base';

.Container {
    display: flex;
    flex-direction: column;
    margin: $spacer1 0;
    & .imagesPreviewContainer {
        margin-bottom: $spacer1;
    }
    & .ErrorMessage {
        height: $spacer075;
        font-size: 1.2rem;
        padding-top: 0.25rem;
        &.CustomErrorMessage {
            margin-top: 0.8rem;
            margin-bottom: 2.4rem;
            font-size: 1.4rem;
            height: unset;
            padding: 0;
        }
    }
    & .InnerContainer {
        display: flex;
        align-items: flex-end;
        column-gap: $spacer05;
        & .InputsContainer {
            flex: 1;
            & .AuthorInputAndCheckboxContainer {
                display: flex;
                justify-content: center;
                align-items: center;
                row-gap: $spacer05;
                & .CheckboxContainer {
                    display: flex;
                    column-gap: $spacer1;
                    margin: 0 $spacer1;
                    padding-bottom: $spacer075;
                    & .Checkbox {
                        margin: 0;
                        white-space: nowrap;

                        input {
                            &:focus {
                                outline: 1px solid var(--third);
                            }
                        }
                    }
                }
            }
            & .AuthorInputContainer,
            .CondolenceTextAreaContainer {
                flex: 1;
                display: flex;
                flex-direction: column;
            }
            & .CondolenceTextAreaContainer {
                & .CondolenceTextArea {
                    height: 17.7rem;
                }
            }
        }
        & .UploadContainer {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding-bottom: $spacer075;
            & .Label {
                @include font-Poppins-Medium;
                margin-bottom: $spacer05;
                text-align: center;
                font-size: 1.2rem;
                line-height: $spacer075;
                color: rgba(var(--text-dark-rgb), 0.6);
                max-width: 24rem;
            }
        }
    }
    & .ButtonContainer {
        display: flex;
        justify-content: center;
        & .CancelButton,
        & .PublishButton {
            width: fit-content;
            min-width: 23rem;
            margin: 0 1rem;
        }
    }
    @include media('<1170px') {
        & .InnerContainer {
            & .InputsContainer {
                & .AuthorInputAndCheckboxContainer {
                    flex-direction: column;
                    align-items: unset;
                    row-gap: 0;
                    & .CheckboxContainer {
                        margin: 0 0 $spacer075;
                        padding-bottom: 0;
                        flex-wrap: wrap;
                        row-gap: $spacer05;
                    }
                }
            }
        }
        & .ButtonContainer {
            margin-top: $spacer025;
        }
    }
    @include media('<940px') {
        & .InnerContainer {
            flex-direction: column;
            align-items: unset;
            & .UploadContainer {
                margin-top: $spacer025;
                padding-bottom: $spacer025 / 2;
                & .Label {
                    order: 1;
                    margin-top: $spacer05;
                    margin-bottom: 0;
                    max-width: unset;
                }
                & .Upload {
                    width: 100%;
                }
            }
        }
        & .ButtonContainer {
            margin-top: $spacer075;
        }
    }
    @include media('<tablet') {
        & .ButtonContainer {
            flex-direction: column;
            row-gap: 1rem;
            & .CancelButton,
            & .PublishButton {
                width: 100%;
                margin: 0;
            }
        }
    }
}
