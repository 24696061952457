@import 'src/styles/base';

.MaskContainer {
    @include centered;
    display: flex;
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 1;
    background: $black;
    cursor: default;
    &.LoadingMaskContainer {
        & .MaskInnerContainer {
            & .MaskIcon {
                svg {
                    @include animation-bounce;
                    animation-iteration-count: infinite;
                    animation-duration: $very-slow;
                    width: 4.5rem;
                    height: 4.5rem;
                }
            }
        }
    }
    & .MaskInnerContainer {
        & .MaskIcon {
            border: 0;
            outline: 0;
            opacity: 1 !important;
            cursor: default;
            svg {
                width: 5rem;
                height: 5rem;
                color: $white;
                filter: drop-shadow(0 0 1px $white);
            }
        }
    }
    &.LightMode {
        background: $white;
        & .MaskInnerContainer {
            & .MaskIcon {
                svg {
                    color: var(--secondary);
                }
            }
        }
    }
}
