@import 'src/styles/base';

.Form {
    display: flex;
    flex-direction: column;
    margin: 0 $spacer3;
    @include media('<tablet') {
        margin: 0 $spacer05;
    }

    & .OrganizationContainer {
        display: flex;
        flex-direction: column;
        row-gap: $spacer1;
        font-size: 1.8rem;

        & .OrganizationHeader {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: $spacer1;
            width: 100%;
            & .OrganizationTitle {
                width: calc(100% - 10rem);
                & .OrganizationName {
                    @include font-Poppins-Regular;
                    line-height: 2.2rem;
                    color: var(--text-dark);
                    word-break: keep-all;
                    text-align: start;
                    font-weight: 600;
                    &::before {
                        content: ' ';
                    }
                    &::after {
                        content: ' ';
                    }
                }
            }
            & .OrganizationLogo {
                height: 8rem;
                width: 8rem;
                flex-shrink: 0;
                object-fit: contain;
                object-position: center;
            }

            @include media('<=450px') {
                flex-direction: column-reverse;
                & .OrganizationTitle {
                    width: 100%;
                }
            }
        }
    }

    & .Headline {
        margin: $spacer1 0;

        &.NoMarginTop {
            margin-top: 0;
        }

        @include media('>tablet') {
            display: block;
        }
    }

    & .FieldsColumn {
        display: flex;
        flex-direction: column;
        gap: $spacer05;
        margin-bottom: $spacer05;
        @include media('<tablet') {
            gap: $spacer025;
        }
    }

    & .FieldsRow {
        display: flex;
        flex-direction: row;
        gap: $spacer075;
        @include media('<tablet') {
            flex-direction: column;
            gap: $spacer025;
        }
    }

    & .Field {
        width: 100%;
    }

    & .ErrorMessage {
        height: $spacer075;
        font-size: 1.2rem;
        padding-top: 0.25rem;
        &.FormErrorMessage {
            margin-top: 4rem;
            font-size: 1.4rem;
            height: unset;
            padding: 0;
        }
    }

    & .Checkbox {
        border-top: 1px solid rgba(var(--secondary-dark-rgb), 0.6);
        border-bottom: 1px solid rgba(var(--secondary-dark-rgb), 0.6);
        padding: 2.5rem 1rem;
        margin: 0;
        input {
            &:focus {
                outline: 1px solid var(--third);
            }
        }
    }

    & .PersonalIdDescription {
        color: var(--text-dark);
    }

    & .AmountDescription {
        color: var(--text-dark);
    }

    & .PaymentInfoContainer {
        display: flex;
        align-items: center;
        justify-content: space-between;

        & .PaymentInfoDescription {
            color: var(--text-dark);
            max-width: 70%;
        }

        & .PaymentInfoIcons {
            display: flex;
            column-gap: 8px;
        }
    }

    & .SubmitButton {
        margin: 4rem auto;
        width: 100%;

        @include media('>phone') {
            min-width: 23rem;
            width: auto;
        }
    }
}

.Tooltip {
    font-family: var(--font-primary) !important;
    font-weight: 500 !important;
    font-size: 1.2rem !important;
}
