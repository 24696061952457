@import 'mixins/common';
@import 'mixins/borders';
@import 'mixins/buttons';
@import 'mixins/navigation';
@import 'mixins/animations';
@import 'typography/font';
@import 'typography/section';
@import 'variables/spacers';
@import 'variables/transitions';
@import './typography';
@import './colors';
@import './rwd';
@import './vg';
@import './helpers';

@font-face {
    font-family: 'Minion Pro';
    src: url('https://memcare-public.s3.eu-central-1.amazonaws.com/fonts/fe9c236a1c142788cb16ec5238c7833e.eot'); /* IE9*/
    src: url('https://memcare-public.s3.eu-central-1.amazonaws.com/fonts/fe9c236a1c142788cb16ec5238c7833e.eot?#iefix')
            format('embedded-opentype'),
        /* IE6-IE8 */
            url('https://memcare-public.s3.eu-central-1.amazonaws.com/fonts/fe9c236a1c142788cb16ec5238c7833e.woff2')
            format('woff2'),
        /* chrome firefox */
            url('https://memcare-public.s3.eu-central-1.amazonaws.com/fonts/fe9c236a1c142788cb16ec5238c7833e.woff')
            format('woff'),
        /* chrome firefox */
            url('https://memcare-public.s3.eu-central-1.amazonaws.com/fonts/fe9c236a1c142788cb16ec5238c7833e.ttf')
            format('truetype'),
        /* chrome firefox opera Safari, Android, iOS 4.2+*/
            url('https://memcare-public.s3.eu-central-1.amazonaws.com/fonts/fe9c236a1c142788cb16ec5238c7833e.svg#Minion Pro')
            format('svg'); /* iOS 4.1- */
}

:root {
    --primary: #{$beige};
    --primary-rgb: #{convertHexToRgb($beige)};

    --secondary: #{$mystic};
    --secondary-rgb: #{convertHexToRgb($mystic)};

    --secondary-dark: #{$beige-mist};
    --secondary-dark-rgb: #{convertHexToRgb($beige-mist)};

    --third: #{$common-yellow};
    --third-rgb: #{convertHexToRgb($common-yellow)};

    --third-dark: #{$common-yellow-dark};
    --third-dark-rgb: #{convertHexToRgb($common-yellow-dark)};

    --success: #{$malachite};
    --success-rgb: #{convertHexToRgb($malachite)};

    --error: #{$valencia};
    --error-rgb: #{convertHexToRgb($valencia)};

    --text-dark: #{$terracotta};
    --text-dark-rgb: #{convertHexToRgb($terracotta)};

    --text-dark-light: #{$bismark-light};
    --text-dark-light-rgb: #{convertHexToRgb($bismark-light)};

    --background-light: #{$athens-gray};
    --background-light-rgb: #{convertHexToRgb($athens-gray)};

    --shadow: #{$alto};
    --shadow-rgb: #{convertHexToRgb($alto)};

    --shadow-color: #{$shadow-color};
    --secondary-light-gray: #{$secondary-light-gray};

    --font-primary: #{$font-primary};
    --font-secondary: #{$font-secondary};
    --font-tertiary: #{$font-tertiary};
    --font-size-basic: 1.4rem;
}
//TODO VG colors - rhird-dark-accent missing
:root[data-theme='vg'] {
    --primary: #{$cool-gray};
    --primary-rgb: #{convertHexToRgb($cool-gray)};

    --third: #{$dark-green};
    --third-rgb: #{convertHexToRgb($dark-green)};

    --third-dark: #{$light-green};
    --third-dark-rgb: #{convertHexToRgb($light-green)};

    --text-dark: #{$cool-gray};
    --text-dark-rgb: #{convertHexToRgb($cool-gray)};

    --shadow-color: #{$vg-shadow-color};

    --font-primary: #{$vg-font-primary};
    --font-tertiary: #{$vg-font-primary};
    --font-size-basic: 1.8rem;
}

html {
    font-size: 62.5%; /* 62.5% of 16px = 10px */
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    -webkit-tap-highlight-color: transparent;
}

body {
    font-family: var(--font-tertiary);
    font-size: var(--font-size-basic);
    margin: 0;
    box-sizing: border-box;
    color: var(--primary);
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
span,
ul,
li {
    margin: 0;
    padding: 0;
}

a {
    text-decoration: none;
}

* {
    box-sizing: inherit;
}

img {
    display: block;
    width: 100%;
}

button {
    background: transparent;
}
