@import '../../../styles/rwd';
@import '../../../styles/colors';

.Modal {
    overflow-y: auto;
    border: 1px solid rgba(var(--secondary-dark-rgb), 0.6);
}

.Overlay {
    background: rgba($white, 0.85);
}
