@import '../../../../styles/colors';
@import '../../../../styles/rwd';

.Container {
    display: flex;
    justify-content: center;
    width: 100%;
    color: var(--third);
    background-color: $white;
    border: 1px solid var(--third);
    border-radius: 3px;
    padding: 1rem;
    margin-bottom: 3rem;

    @include media('>tablet') {
        padding: 2rem;
    }

    &.passed {
        color: var(--error);
        border: 1px solid var(--error);
    }
}
