@import '../variables/transitions';

@mixin hoverable {
    opacity: 1;
    transition: opacity $faster;
    cursor: pointer;
    &:hover {
        opacity: 0.8;
    }
}

@mixin centered {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

@mixin rounded {
    border-radius: 0.3rem;
}
