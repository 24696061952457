$very-slow: 2000ms;
$slow: 1000ms;
$fast: 400ms;
$faster: 200ms;
$fastest: 100ms;

:export {
    very-slow: $very-slow;
    slow: $slow;
    fast: $fast;
    faster: $faster;
}
