@import 'src/styles/base';

.ImageWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    &.ImageWrapperMobile {
        display: none;
        @include media('<tablet') {
            display: flex;
        }
    }
    &.ImageWrapperDesktop {
        display: flex;
        @include media('<tablet') {
            display: none;
        }
    }
    & .ImageContainer {
        justify-self: center;
        align-self: center;
        height: max-content !important;
        width: max-content !important;
        max-height: 100%;
        max-width: 100%;
        @include media('<tablet') {
            width: 100% !important;
        }
        & .Image {
            object-fit: contain;
            max-height: 25rem;
            & + div:not(:only-of-type):first-of-type {
                height: max-content !important;
                width: max-content !important;
                top: 50% !important;
                left: 50% !important;
                transform: translate(-50%, -50%) !important;
            }
            @include media('<tablet') {
                & + div:not([class]) {
                    height: max-content !important;
                    width: max-content !important;
                    top: 50% !important;
                    left: 50% !important;
                    transform: translate(-50%, -50%) !important;
                }
            }
        }
    }
}
.EnlargedImageContainer {
    background: white;
    width: 100% !important;
    height: 100% !important;
    border: 1px solid var(--secondary-dark);
    @include media('<tablet') {
        height: 102% !important;
        top: -1% !important;
    }
}
