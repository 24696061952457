@import 'src/styles/base';

.ImageCarousel {
    position: relative;
    &.Fullscreen {
        position: fixed;
        top: 0;
        left: 0;
        height: 100vh;
        width: 100vw;
        z-index: 999;
    }
}

.CustomView {
    min-height: 300px;
    background: $black;
    & vm-loading-screen {
        min-height: 300px;
    }
    &.CustomViewFullscreen {
        @include centered;
        height: 100vh;
        width: 100vw;

        & .CustomViewImage {
            & img {
                max-height: 100vh;
                height: auto;
                object-fit: cover;
            }
        }
    }
}

.CustomViewImage {
    & img {
        max-height: 300px;
        height: 300px;
        width: 100%;
        object-fit: cover;
        min-width: 100%;
        min-height: 100%;
        pointer-events: none;
    }
}

.Icon {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1.2rem;
    color: $white;
    width: 6rem;
    height: 6rem;
    font-size: 1.4rem;
    white-space: nowrap;
    font-weight: bold;
    transition: $faster;
    filter: drop-shadow(0 0 1px var(--text-dark));
    &:hover {
        @include media('>tablet') {
            transform: scale(1.25);
        }
    }
}

.NavigationButton {
    position: absolute;
    top: 50%;
    transform: translate(0%, -50%);
    border: 0 !important;
    outline: 0 !important;
    padding: 0 $spacer05;
    &:hover {
        & .NavigationIcon {
            @include media('>tablet') {
                transform: scale(1.25);
            }
        }
    }
    &.NavigationButtonNext {
        right: 0;
        padding-right: 0;
    }
    &.NavigationButtonPrev {
        left: 0;
        padding-left: 0;
    }

    & .Icon {
        &.NavigationIcon {
            svg {
                height: 32px;
                width: 32px;
            }
        }
    }
}

.Header {
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
    z-index: 1;
    width: 100%;

    & .Icon {
        position: absolute;
        top: 0;

        &.CloseIcon {
            @include hoverable;
            right: 0;
            pointer-events: all;
            transition-property: all;
            svg {
                height: 32px;
                width: 32px;
            }
        }

        &.CountIcon {
            left: 0;
        }
    }
}

.Footer {
    position: absolute;
    bottom: 0;
    right: 0;
    pointer-events: none;
    z-index: 1;
    width: 100%;

    & .Icon {
        position: absolute;
        bottom: 0;

        &.FullscreenIcon {
            @include hoverable;
            right: 0;
            pointer-events: all;
            transition-property: all;
            svg {
                width: 32px;
                height: 32px;
            }
        }
    }
}
