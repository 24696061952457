@mixin font-Merriweather-Regular {
    font-family: var(--font-secondary);
    font-weight: 400;
}

@mixin font-Merriweather-Medium {
    font-family: var(--font-secondary);
    font-weight: 500;
}

@mixin font-Merriweather-Bold {
    font-family: var(--font-secondary);
    font-weight: 700;
}

@mixin font-Poppins-SemiBold {
    font-family: var(--font-tertiary);
    font-weight: 600;
}

@mixin font-Poppins-Medium {
    font-family: var(--font-tertiary);
    font-weight: 500;
}

@mixin font-Poppins-Regular {
    font-family: var(--font-tertiary);
    font-weight: 400;
}
