@import '../../../styles/colors';
@import '../../../styles/rwd';
@import '../../../styles/vg';

.Container {
    text-align: center;
    max-width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 0.5;
    z-index: 5;

    @include media('>tablet') {
        display: block;
        max-width: none;
    }

    @include media('<600px') {
        margin-top: 10px;
    }

    @include media('<500px') {
        margin-top: 20px;
    }

    @include media('<400px') {
        margin-top: 30px;
    }
}

.Label {
    position: relative;
    font-size: 1rem;
    text-transform: uppercase;
    color: var(--third-dark);
    font-weight: 600; // TODO: Variables
    display: inline-block;
    margin-bottom: 0.5rem;
    max-width: 70px;
    letter-spacing: 0.6px;

    &::before,
    &::after {
        position: absolute;
        content: '';
        top: calc(50% - 1px);
        border-top: 1px solid var(--third-dark);
        width: 0.8rem;
        display: block;

        @include media('>tablet') {
            top: 50%;
            width: 1rem;
        }
    }

    &::before {
        left: calc(100% + 0.5rem);
    }

    &::after {
        right: calc(100% + 0.5rem);
    }

    @include media('>tablet') {
        font-size: 1.2rem;
        margin-bottom: 1rem;
        max-width: none;
    }
}

.Label.vg {
    font-family: $vg-font-secondary;
    font-weight: 400;
    color: $black;
    margin-bottom: 0.5rem;

    &::before,
    &::after {
        display: none;
    }

    @include media('>tablet') {
        font-size: 11pt;
    }
}

.Date {
    display: block;
    font-size: 1.2rem;
    font-weight: 600;

    @include media('>tablet') {
        font-size: 2rem;
    }
}

.NoDate {
    @extend .Date;
    display: none;

    @include media('>tablet') {
        display: block;
    }
}

.NoDateMobile {
    @extend .Date;

    @include media('>tablet') {
        display: none;
    }
}

.Date.vg,
.NoDate.vg,
.NoDateMobile.vg {
    @include media('>tablet') {
        font-size: 14pt;
    }
}

.Place {
    display: block;
    min-width: 100px;
    word-break: break-word;
    letter-spacing: 0.6px;
}
