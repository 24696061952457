@import 'src/styles/base';

.Sections {
    display: grid;
    grid-template-columns: 0.55fr 0.45fr;
    grid-gap: 2rem;
    &.OneSection {
        grid-template-columns: 1fr;
    }
    &.FirstSection {
        @include media('>tablet') {
            margin-top: 4rem;
        }
    }
    &.LastSection {
        margin-bottom: 4rem;
    }

    @include media('<tablet') {
        grid-template-columns: 1fr;
        grid-gap: unset;
    }
}

.LeftSection {
    @include media('<tablet') {
        padding: 3.2rem 2rem;
        border-top: 1.5px solid rgba(var(--secondary-dark-rgb), 0.6);
        border-bottom: 1.5px solid rgba(var(--secondary-dark-rgb), 0.6);
        &:not(.FullWidth) {
            border-bottom: unset;
        }
    }
}

.RightSection {
    display: flex;
    flex-direction: column;
    gap: $spacer05;
    padding: unset;
    border: unset;
    &.FullWidth {
        gap: 4rem;
    }

    @include media('<tablet') {
        gap: unset;
        border-top: 1.5px solid rgba(var(--secondary-dark-rgb), 0.6);
        border-bottom: 1.5px solid rgba(var(--secondary-dark-rgb), 0.6);
        & > div {
            border: unset;
            padding: 3.2rem 2rem;
        }
        & > :nth-child(n + 2) {
            border-top: 1.5px solid rgba(var(--secondary-dark-rgb), 0.6);
        }
    }
}

.CondolencesDisplay {
    margin-bottom: 4rem;

    @include media('>tablet') {
        margin-top: 4rem;
    }
}
