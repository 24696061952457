@import '../../../styles/colors';

.BasketContainer {
    cursor: pointer;
    position: relative;

    span {
        position: absolute;
        border-radius: 50%;
        background-color: var(--third-dark);
        color: $white;
        bottom: 0;
        right: -2px;
        width: 14px;
        height: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
    }
}
