@import 'src/styles/base';

.DownloadsItem {
    width: 100%;
    height: 100%;
}

.DownloadsItemNoPreview {
    text-align: center;
    white-space: pre-wrap;
}

.DownloadsItemImage {
    height: auto;
    max-height: 100%;
    max-width: 100%;
    width: auto;

    img {
        width: 100%;
    }
}

.DownloadsItemImage.SmallHeightDN {
    width: 90%;
    max-width: 100%;
}

.DownloadsContainer {
    position: relative;
    height: 35rem;
    padding: 3rem 1rem;
    border: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid var(--secondary);
    box-shadow: 0 2px 4px 0 var(--secondary);
    flex-shrink: 0;

    @include media('>tablet') {
        height: 40rem;
    }
}

.DownloadsLink {
    @include hoverable;
    position: absolute;
    bottom: 2rem;
    right: 2rem;
    background-color: var(--primary);
    color: white;
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.DownloadsName {
    position: absolute;
    top: 0.5rem;
    left: 0;
    width: 100%;
    text-align: center;
}

.BottomTitle {
    top: unset;
    bottom: 0.5rem;
    padding: 0 0.5rem;
    font-size: 1.2rem;
}
