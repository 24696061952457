@import 'src/styles/base';

.TextArea {
    @include font-Poppins-Regular;
    border: 1px solid var(--secondary-dark);
    border-radius: 3px;
    color: var(--primary);
    font-size: var(--font-size-basic);
    outline: 0;
    padding: $spacer05;
    resize: none;
    width: 100%;

    &::-webkit-input-placeholder {
        color: rgba(var(--text-dark-rgb), 0.8);
    }
}
