@import 'src/styles/base';

.Counter {
    align-items: center;
    display: flex;
}

.IconWrapper {
    align-items: center;
    border: 1px solid var(--third-dark);
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    padding: 0.6rem;
    transition: box-shadow 0.3s;
    color: var(--third-dark);

    &:focus {
        box-shadow: none;
        outline: none;
    }

    &:hover {
        box-shadow: 0 2px 7px 0 rgba(var(--third-dark-rgb), 0.5);
    }

    @include media('<tablet') {
        padding: 0.9rem;
    }
}

.Disabled {
    background: var(--background-light);
    color: var(--secondary-dark);
    border-color: rgba(var(--secondary-dark-rgb), 0.6);

    &:hover {
        box-shadow: none;
    }
}

.Icon {
    color: inherit;
}

.Label {
    color: var(--text-dark);
    display: block;
    font-size: 1.8rem;
    margin: 0 1.5rem;
}
