@import 'src/styles/base';

.Item {
    border-top: 1px solid rgba(var(--secondary-dark-rgb), 0.6);
    padding: 2.4rem 0;

    &:first-of-type {
        margin-top: 2.4rem;
    }
}

.FlowersCategory {
    @include font-Poppins-Medium;
    color: var(--text-dark);
    display: block;
    font-size: 1.6rem;
    margin-bottom: 0.8rem;
}

.VariantName {
    @include font-Poppins-Medium;
    color: var(--text-dark);
    font-size: 1.6rem;
}

.AdjustmentsInfo {
    color: var(--text-dark);
    display: block;
    margin-bottom: 1rem;
}

.BandText {
    color: var(--text-dark);
    display: block;
    font-style: italic;
}

.Controls {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 2rem 0;
    gap: 2.3rem;
}

.InfoWithPhoto {
    display: flex;
}

.Info {
    flex: 1;
}

.Photo {
    height: 100%;
    width: 8rem;
    object-fit: contain;
}

.VariantPicker {
    width: 60%;
}

.Icons {
    display: flex;

    .IconWrapper:first-of-type {
        margin-right: 0.8rem;
    }
}

.IconWrapper {
    align-items: center;
    background-color: var(--secondary-dark);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    transition: background-color 0.3s;
    padding: 1rem;

    &:hover {
        background-color: var(--text-dark);
    }
}

.Icon {
    color: $white;
}

.QuantityAndPrices {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.Prices {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
}

.TotalBox {
    display: flex;
    white-space: nowrap;

    .TotalLabel:first-of-type {
        margin-right: 2.3rem;
    }
}

.TotalLabel {
    color: var(--text-dark);
    font-size: 1.6rem;
}

.TotalLabelBold {
    @include font-Poppins-Medium;
}
