.InputsContainer {
    max-width: 39rem;
    width: 100%;
    margin: 0 auto;
}

.HintText {
    text-align: center;
    margin-bottom: 3rem;
}

.Public {
    margin-top: 3rem;
}

.Control {
    margin-bottom: 2rem;
    display: block;
}

.SubmitButton {
    margin: 3rem auto 2rem;
    min-width: 27rem;
    display: flex;
}
