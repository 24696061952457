@import 'src/styles/rwd';

.DeliveryTypeContainer {
    display: flex;
    flex-direction: column;
    padding: 2rem 0;

    & .DeliveryTypeItem {
        font-size: 1.5rem;
        padding: 2rem 0;
    }

    @include media('>=tablet') {
        flex-direction: row;
        gap: 4rem;
    }
}

.CeremonyAddress {
    margin-bottom: 4rem;
}

.ExtraDataForm {
    margin-bottom: 4rem;
}

.ButtonContainer {
    display: flex;
    justify-content: flex-end;
}

.ListRow {
    display: flex;
    color: var(--text-dark);
    margin-bottom: 1.5rem;
}

.IconContainer {
    width: 3.5rem;
    margin-right: 2rem;
    background: rgba(var(--secondary-dark-rgb), 0.2);
    border-radius: 50%;
    height: 3.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 0 0 3.5rem;
}

.Icon {
    width: 2rem;
    height: 2rem;
}

.RowContent {
    margin-top: 0.5rem;
}
