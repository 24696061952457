@import 'src/styles/base';

.Container {
  padding: 3.2rem 2rem 0 2rem;
  margin: 4rem 0;

  & .Title {
    @include media('>tablet') {
      text-align: center;
    }
  }
}
