@import '../../../../styles/colors';
@import '../../../../styles/rwd';

.FlowersSummaryListing {
    display: none;

    @include media('>tablet') {
        display: block;
    }
}

.Columns {
    display: flex;
    margin-bottom: 1.5rem;
    margin-top: 4rem;
    & span {
        flex: 1;
        &:first-of-type {
            flex: 0.75;
        }
    }
}

.Column {
    color: var(--text-dark);
    font-weight: 500;
    text-align: center;
}

.ColumnLeftAligned {
    text-align: left;
}
