@import 'src/styles/base';

.Form {
    margin-bottom: 6rem;
    padding: 0 3.2rem;

    @include media('>tablet') {
        padding: 0;
    }
}

.FieldsContainer {
    display: flex;
    flex-direction: column;

    @include media('>tablet') {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 2rem;
    }
}

.TextFieldContainer {
    margin-bottom: 1.6rem;
    width: 100%;

    @include media('>tablet') {
        margin-bottom: 0;
        margin-right: 1.6rem;
    }

    &:last-of-type {
        margin: 0;
    }
}

.ButtonContainer {
    display: flex;
    justify-content: flex-end;
    margin-top: 1.6rem;
}

.ButtonContainerWithMessage {
    flex-direction: column;
    justify-content: space-between;

    @include media('>tablet') {
        align-items: center;
        flex-direction: row;
    }
}

.Button {
    margin-top: 3.4rem;
    width: 100%;

    @include media('>tablet') {
        margin-top: 0;
        width: initial;
    }
}

.ButtonWithMessage {
    margin-top: 0;
}

.Subheader {
    @include font-Poppins-Medium;
    color: var(--third-dark);
    display: block;
    font-size: 1.8rem;
    margin-bottom: 2.4rem;
    text-align: center;
}

.InfoMessage {
    color: var(--primary);
    font-weight: 700;
    margin-bottom: 1.6rem;

    @include media('>tablet') {
        margin-bottom: 0;
    }
}
