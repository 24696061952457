@import '../../../styles/colors';

.SecondaryButton {
    border: 1px solid var(--third-dark);
    color: var(--third-dark);
}

.SecondaryButtonIconLeft {
    flex-direction: row-reverse;
    padding: 0 2.4rem 0 0;

    .SecondaryButtonIconBox {
        border-left: 0;
        border-bottom-left-radius: 3px;
        border-radius: 0;
        border-right: 1px solid var(--third-dark);
        border-top-left-radius: 3px;
        margin-left: 0;
        margin-right: 2.4rem;
    }
}

.SecondaryButtonIconBox {
    border-left: 1px solid var(--third-dark);
    height: 4.8rem;
    width: 4.9rem;
}
