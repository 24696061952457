.Container {
    position: relative;
    overflow: hidden;
    display: flex;
    width: 100%;
    flex-direction: column;
}

.PreviewList {
    overflow-x: auto;
    margin-top: 5px;
    display: flex;
    width: 100%;
    height: 150px;
    flex-direction: row;
    background-color: #f6f6f65e;
    border: 1px solid rgba(0, 0, 0, 0.3);
    border-radius: 4px;
}

.PreviewListItem {
    position: relative;
    padding: 7px;
    background-color: rgb(128, 128, 128);
    margin-right: 7px;
    border-radius: 4px;
    height: 100%;
}

.PreviewListItemHovered {
    background-color: rgba(128, 128, 128, 0.3);
}

.PreviewListItemImage {
    display: block;
    height: 100%;
    border-radius: 4px;
}

.Hovered {
    opacity: 0.5;
}

.Placeholder {
    width: 110px;
    background-color: white;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.IconContainer {
    display: flex;
    position: absolute;
    right: 7px;
    top: 7px;
    gap: 5px;
}

.Icon {
    background-color: white;
    border-radius: 4px;
}

.VideoPreviewIcon {
    width: 40px !important;
    height: 40px !important;
}
