@import '../../../styles/rwd';

.VideoStreamSection {
    margin: 3.2rem 0;
}

.SectionTitle {
    margin-bottom: 3.2rem;
}

.SectionSubtitle {
    margin-bottom: 1rem;
}
