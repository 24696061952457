@import 'src/styles/base';

.ShareTitle {
    @include font-Poppins-Medium;
    color: var(--third-dark);
    display: block;
    font-size: 1.8rem;
    margin-bottom: 2.4rem;
    text-align: center;
}

.ShareBox {
    @include hoverable;
    border: 1px solid var(--secondary-dark);
    border-radius: 3px;
    display: flex;
    justify-content: space-between;
    max-height: 5.2rem;
}

.ShareLink {
    color: var(--text-dark);
    overflow-x: auto;
    white-space: nowrap;
    display: flex;
    max-height: 100%;
    align-items: center;
    padding: 0 1.5rem;

    &::-webkit-scrollbar {
        display: 'none';
    }
}

.ShareIconBox {
    align-items: center;
    background-color: var(--secondary-dark);
    display: flex;
    height: 5rem;
    justify-content: center;
    min-width: 5rem;
    width: 5rem;
}

.ShareIcon {
    color: $white;
}

.CopiedText {
    @include font-Poppins-Medium;
    color: var(--text-dark);
    display: block;
    margin-top: 1.4rem;
    text-align: right;
}

.QrCodeBox {
    display: flex;
    justify-content: center;
    margin-top: 2.4rem;
}

.QrCode {
    border: 1px solid var(--secondary-dark);
    border-radius: 3px;
    width: 200px;
    max-width: 80%;
}