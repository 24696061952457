@import 'src/styles/base';

.Container {
    display: flex;
    flex-direction: column;
    padding: 3rem 2rem 1rem;

    @include media('>tablet') {
        align-items: center;
        padding: 3rem 0 1rem;
    }
}

.Headline {
    color: var(--primary);
    font-size: 1.8rem;
    font-weight: bold;
    margin-bottom: 3.2rem;
    text-align: center;

    @include media('>tablet') {
        font-size: 2.6rem;
    }
}

.SuccessMessage {
    @include font-Poppins-Medium;
    color: var(--third-dark);
    font-size: 1.6rem;
    margin-bottom: 3.5rem;

    @include media('>tablet') {
        font-size: 1.8rem;
        margin-bottom: 7rem;
    }
}

.DataSet {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 2rem;

    @include media('>tablet') {
        flex-direction: row;
    }
}

.DataSetLabel {
    @include font-Poppins-Medium;
    color: var(--text-dark);
    display: block;
    font-size: 1.6rem;
    margin-bottom: 1rem;

    @include media('>tablet') {
        margin-bottom: 0;
        margin-right: 2rem;
        text-align: right;
        width: 20rem;
    }
}

.DetailsSentTo {
    &::after {
        content: ': ';
    }
}

.DataSetContainer {
    @include media('>tablet') {
        width: 40rem;
    }
}

.DataSetInfo {
    color: var(--text-dark);
}

.OrderDeliveryContainer {
    display: flex;
    flex-direction: column;
}

.OrderDeliveryPlace {
    @include font-Poppins-Medium;
    color: var(--third-dark);
    font-size: 1.8rem;
    margin-bottom: 1rem;

    @include media('>tablet') {
        margin-bottom: 2.4rem;
    }
}

.OrderDeliveryInfoPair {
    align-items: center;
    display: flex;
    margin-bottom: 1.6rem;

    &:last-of-type {
        margin-bottom: 3rem;
    }

    .DataSetInfo {
        margin-left: 1.6rem;
    }
}

.OrderDeliveryIconWrapper {
    align-items: center;
    background-color: rgba(var(--secondary-dark-rgb), 0.2);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    padding: 0.8rem;
    width: 3.2rem;
}

.OrderDeliveryIcon {
    color: var(--text-dark);
}

.HomeButton {
    margin: 2rem 0 5rem;
    width: 100%;

    @include media('>tablet') {
        margin-bottom: 0;
        margin-top: 4.8rem;
        width: initial;
    }
}
