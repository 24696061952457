@import 'src/styles/base';

.Container {
    @include animation-slide-up;
    @include section-separator-bar-top;
    display: flex;
    justify-content: space-between;
    position: fixed;
    bottom: 0;
    z-index: 99;
    width: 100%;
    background: var(--background-light);
    &.Accepted {
        @include animation-slide-down;
    }

    & .InnerContainer {
        display: flex;
        justify-content: space-between;
        & .Content {
            padding: $spacer05 0 $spacer05 $spacer05;
            & .Text {
                font-size: 1.2rem;
                text-align: left;
                &:after {
                    content: '\00a0';
                }
            }
            & .Link {
                @include hoverable;
                @include font-Poppins-Medium;
                display: inline;
                font-size: 1.2rem;
                text-align: left;
                color: var(--third-dark);
                padding: 0;
            }
        }
        & .IconContainer {
            @include hoverable;
            @include centered;
            padding: $spacer05;
            & .Icon {
                transform: rotateZ(45deg);
            }
        }
    }
}
