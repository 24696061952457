@import '../../../../styles/rwd';

.Form {
    display: grid;
    grid-gap: 1rem;

    @include media('>=tablet') {
        grid-gap: 2rem;
        grid-template-columns: 2fr 1fr;
    }
}

.BandExamples {
    margin-top: 0;
}

.FullRow {
    grid-column: 1/-1;
}
