@import '../../../styles/colors';
@import '../../../styles/rwd';

.CompanyLogo {
    height: 100%;
}

.Logo {
    max-height: 45px;
    max-width: 115px;
    display: block;
    width: auto;
    height: auto;

    @include media('>tablet') {
        max-height: 90px;
        max-width: 210px;
    }
}
