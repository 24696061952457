.Container {
    text-align: initial;
    & ul,
    ol,
    li {
        margin: 0;
        padding: revert;
    }
    & h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        margin: 0;
    }
}

.WithHeadingsMargin {
    & h2 {
        margin: revert;
    }
}
