@import '../../../styles/colors';

.DeceasedAvatar {
    border-radius: 50%;

    // Overrideable
    width: 25rem;
    height: 25rem;
    object-fit: contain;
    background-color: $black;
}
