.InputsContainer {
    margin: 0 auto;
    max-width: 39rem;
    width: 100%;
}

.HintText {
    margin-bottom: 3rem;
    margin-top: 2rem;
    text-align: center;
}

.Control {
    display: block;
    margin-bottom: 2rem;
}

.SubmitButton {
    display: flex;
    margin: 3rem auto 2rem;
    max-width: 27rem;
    width: 100%;
}
