@import 'src/styles/base';

.Divider {
    border-bottom: 1px solid rgba(var(--secondary-dark-rgb), 0.6);
}

.Header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: -3.2rem;
    margin-right: -3.2rem;
    padding: 2rem 3.2rem;

    &.Hoverable {
        @include hoverable;
        &:hover {
            cursor: pointer;
            background-color: rgba(var(--third-rgb), 0.13);
        }
    }
}

.HeaderText {
    @include font-Poppins-SemiBold;
    color: var(--third-dark);
    font-size: 1.8rem;
    cursor: unset;
    align-self: flex-end;
}

.Content {
    padding-bottom: 2rem;
}

.Icon {
    transform: rotate(180deg);
    height: 2em;
    width: 2em;
    cursor: unset;
    transition: opacity $fastest;
    & g {
        stroke: none;
        & path {
            stroke: $white;
        }
        & rect {
            opacity: 1;
            fill: var(--third-dark);
            stroke: none;
        }
    }
    &.IconOpen {
        opacity: 0;
    }
}

.vg {
    & .Icon {
        height: 1.5em;
        width: 1.5em;
    }
}
