@import 'src/styles/base';

.SectionHeader {
    @include font-Merriweather-Bold;
    font-size: 1.8rem;
    margin-bottom: $spacer05;

    @include media('>tablet') {
        font-size: 2.65rem;
        margin-bottom: $spacer1;
    }
}
