@import 'src/styles/base';

.Gallery {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    padding: 4rem 2rem 0;
    column-gap: 10px;
    row-gap: 10px;

    @include media('>tablet') {
        grid-template-columns: repeat(2, 1fr);
        justify-content: start;
        padding: 0.5rem;
    }

    @include media('>desktop') {
        grid-template-columns: repeat(3, 1fr);
    }
}

.SectionHeader {
    padding-left: 2.5rem;

    @include media('>tablet') {
        padding-left: 0;
    }
}

.SectionActions {
    padding: 0 2.5rem;

    @include media('>tablet') {
        padding: 0;
        margin-top: 0;
    }
}
