@import 'src/styles/base';

.Examples {
    display: flex;
    flex-wrap: wrap;
    margin-top: 2.4rem;

    @include media('>tablet') {
        margin-top: 3.2rem;
    }
}

.Example {
    @include font-Poppins-Regular;
    @include centered;
    flex-direction: column;
    align-items: flex-start;
    background-color: rgba(var(--secondary-dark-rgb), 0.2);
    border: 1px solid var(--secondary-dark);
    border-radius: 3px;
    color: var(--text-dark);
    cursor: pointer;
    font-size: 1.2rem;
    margin: 0 0.8rem 0.8rem 0;
    padding: 0.8rem;
    transition: box-shadow 0.3s, background-color 0.3s, color 0.3s;

    &:hover {
        box-shadow: 0 2px 7px 0 rgba(var(--secondary-dark-rgb), 0.5);
    }
}

.ExampleSelected {
    background-color: var(--text-dark);
    color: $white;
}

.Toggle {
    border: 1px solid var(--third-dark);
    border-radius: 3px;
    color: var(--third-dark);
    cursor: pointer;
    font-size: 1.2rem;
    font-weight: 500;
    margin: 0 0.8rem 0.8rem 0;
    padding: 0.8rem;
    transition: background-color 0.3s, color 0.3s;

    &:hover {
        background-color: var(--third-dark);
        color: $white;
    }
}
