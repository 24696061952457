@import '../../../styles/colors';
@import '../../../styles/rwd';

.FlowersContent {
    margin-top: 4rem;
    margin-bottom: 12rem;
    padding-left: 2rem;
    padding-right: 2rem;

    @include media('>425px') {
        padding-left: 4rem;
        padding-right: 4rem;
        margin-bottom: 4rem;
    }

    @include media('>desktop') {
        padding-left: 10rem;
        padding-right: 10rem;
    }
}

.StepHeadline {
    color: var(--third-dark);
    display: block;
    text-align: center;
    margin-bottom: 1rem;
    font-size: 1.6rem;
}

.Headline {
    text-align: center;
}

.Progress {
    margin-bottom: 4rem;

    @include media('>tablet') {
        margin-bottom: 8rem;
    }
}

.DeadlineContainer {
    display: flex;
    justify-content: center;
    width: 100%;
    color: $white;
    background-color: var(--third);
    border-radius: 3px;
    padding: 1rem;
    margin-bottom: 3rem;

    @include media('>tablet') {
        padding: 2rem;
    }
}
