@import 'src/styles/base';

.TimelineEvent {
    background-color: rgba(var(--secondary-dark-rgb), 0.1);
    border-radius: 3px;
    display: flex;
    margin-bottom: 2.4rem;
    padding: 2.4rem;
    position: relative;

    &:last-of-type {
        margin-bottom: 0;
    }
}

.Line {
    background-color: var(--third-dark);
    left: 2.75rem;
    height: calc(100% + 2rem);
    position: absolute;
    top: 3.5rem;
    width: 1px;
}

.Container {
    display: flex;
    flex-direction: column;

    @include media('>tablet') {
        flex-direction: row;
    }
}

.Ball {
    background-color: var(--third-dark);
    border-radius: 50%;
    display: block;
    height: 0.8rem;
    margin-right: 1.6rem;
    margin-top: 0.7rem;
    min-width: 0.8rem;
    width: 0.8rem;
}

.Date {
    @include font-Poppins-Medium;
    color: var(--third-dark);
    font-size: 1.8rem;
}

.InfoBox {
    display: flex;
    flex-direction: column;
    margin-top: 1.6rem;

    @include media('>tablet') {
        margin-left: 5.2rem;
        margin-top: 0;
    }
}

.Title {
    @include font-Poppins-Medium;
    color: var(--text-dark);
    font-size: 1.6rem;
}

.Description {
    color: var(--text-dark);
    font-size: 1.4rem;
    margin-top: 1.6rem;
}
