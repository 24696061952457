@import 'src/styles/base';

.Container {
    display: flex;
    flex-direction: column;
    padding: 3rem 2rem;

    @include media('>tablet') {
        align-items: center;
        padding: 3rem 0 0;
    }
}

.Headline {
    font-size: 1.8rem;
    font-weight: bold;
    margin-bottom: 2.4rem;
    text-align: center;

    @include media('>tablet') {
        font-size: 2.6rem;
        margin-bottom: 3.2rem;
    }
}

.ErrorMessage {
    @include font-Poppins-Medium;
    color: $valencia;
    font-size: 1.6rem;
    margin-bottom: 5.8rem;
    text-align: center;

    @include media('>tablet') {
        font-size: 1.8rem;
    }
}

.DataSet {
    display: flex;
    flex-direction: column;
    margin-bottom: 2.4rem;

    @include media('>tablet') {
        flex-direction: row;
        margin-bottom: 3.7rem;
    }
}

.DataSetLabel {
    color: var(--text-dark);
    font-size: 1.6rem;
    font-weight: bold;
    margin-bottom: 0.8rem;

    @include media('>tablet') {
        margin-bottom: 0;
        text-align: right;
        width: 15rem;
    }
}

.DataSetContent {
    @include media('>tablet') {
        margin-left: 2.6rem;
        width: 30rem;
    }
}

.DataSetInfo {
    color: var(--text-dark);
    font-size: 1.4rem;
}

.Button {
    width: 100%;

    @include media('>tablet') {
        width: initial;
    }
}
