@import 'src/styles/base';

.FlowersSummaryDescription {
    color: var(--text-dark);
}

.AddFlowersContainer {
    align-items: center;
    border-top: 1px solid rgba(var(--secondary-dark-rgb), 0.6);
    border-bottom: 1px solid rgba(var(--secondary-dark-rgb), 0.6);
    display: flex;
    justify-content: center;
    padding: 2.5rem 0;
}

.CondolenceHeadline {
    display: none;
    margin: 3.2rem 0 3.2rem;

    @include media('>tablet') {
        display: block;
    }
}

.CreditCardHeadline {
    display: none;
    margin-bottom: 3.2rem;
    padding-top: 3.2rem;
    @include media('<tablet') {
        margin: 0 0 3.2rem;
    }

    @include media('>tablet') {
        display: block;
    }
}

.PaymentMethodHeadline {
    margin-bottom: 3.2rem;
    padding-top: 3.2rem;
    @include media('<tablet') {
        padding-top: 0;
    }
}

.PaymentMethodDescription {
    color: var(--text-dark);
    line-height: 1.57;
}

.PaymentMethodSelectorContainer {
    display: flex;
    flex-direction: column;
    padding: 2rem 0 2.5rem;
    margin-bottom: 3.2rem;
    border-bottom: 1px solid rgba(var(--secondary-dark-rgb), 0.6);

    & .PaymentMethodSelectorItem {
        flex: 1;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1rem 0;
        font-size: 1.5rem;
        & .PaymentMethodSelectorIcons {
            display: flex;
            align-items: center;
            gap: 0.8rem;
        }

        @include media('<tablet') {
            padding: 2rem 0;
        }
    }
}

.CreditCardContainer {
    display: none;
    padding: 2.5rem 0;
    margin-bottom: 3.2rem;
    border-top: 1px solid rgba(var(--secondary-dark-rgb), 0.6);
    border-bottom: 1px solid rgba(var(--secondary-dark-rgb), 0.6);
    justify-content: space-between;

    @include media('>tablet') {
        display: flex;
    }
}

.CreditCardIcons {
    display: flex;
}

.CreditCardIcon {
    margin-right: 0.8rem;

    &:last-of-type {
        margin-right: 0;
    }
}

.CreditCardTitle {
    @include font-Poppins-Medium;
    color: var(--text-dark);
    display: block;
    font-size: 1.6rem;
    margin-bottom: 2.4rem;
}

.CreditCardDescription {
    color: var(--text-dark);
    line-height: 1.57;
    max-width: 70%;
}

.Delivery {
    display: flex;
    flex-direction: column;
    padding-bottom: 3.2rem;
    @include media('>tablet') {
        justify-content: space-between;
        border-bottom: 1px solid rgba(var(--secondary-dark-rgb), 0.6);
    }
}

.IconWrapper {
    align-items: center;
    background-color: var(--secondary-dark);
    border-radius: 50%;
    cursor: pointer;
    display: inline-flex;
    justify-content: center;
    padding: 1rem;
    transition: background-color 0.3s;

    &:hover {
        background-color: var(--text-dark);
    }

    @include media('>tablet') {
        max-height: 3.2rem;
        max-width: 3.2rem;
        padding: 0.8rem;
    }
}

.Icon {
    color: $white;
}

.TotalContainer {
    display: flex;
    flex-direction: column;
    padding-bottom: 2.3rem;
    margin: 2rem 0 3.2rem;
    border-bottom: 1px solid rgba(var(--secondary-dark-rgb), 0.6);

    @include media('>tablet') {
        margin: 2.4rem 0 0;
    }
}

.TotalRow {
    display: flex;
    justify-content: space-between;

    &:first-of-type {
        margin-bottom: 1.6rem;
    }

    .TotalLabel:first-of-type {
        margin-right: 5.4rem;
    }
}

.TotalLabel {
    color: var(--text-dark);
    font-size: 1.6rem;
}

.TotalLabelBold {
    font-weight: 600;
}

.TotalSeparator {
    background-color: rgba(var(--secondary-dark-rgb), 0.6);
    height: 1px;
    margin: 2.3rem 0;
    width: 100%;
}

.PayButtonContainer {
    align-items: center;
    display: flex;
    justify-content: center;
    margin-bottom: 3rem;
    margin-top: 4rem;

    @include media('>tablet') {
        margin-bottom: 0;
    }
}

.PayButton {
    width: 100%;

    @include media('>tablet') {
        width: fit-content;
    }
}

.ErrorMessage {
    margin-top: 28px;
    text-align: center;

    &.Error {
        margin-top: 5px;
    }
}

.Checkbox {
    border-top: 1px solid rgba(var(--secondary-dark-rgb), 0.6);
    border-bottom: 1px solid rgba(var(--secondary-dark-rgb), 0.6);
    padding: 2.5rem 1rem;
    margin: 0;
    input {
        &:focus {
            outline: 1px solid var(--third);
        }
    }
}
