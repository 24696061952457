@import '../../../styles/colors';
@import '../../../styles/rwd';

$desktopSize: 4rem;
$mobileSize: 3rem;

.Container {
    display: flex;
    justify-content: space-between;
    position: relative;

    &::after {
        position: absolute;
        border-top: 1px solid var(--secondary-dark);
        top: 50%;
        left: 0;
        width: 100%;
        content: '';
    }
}

.StepItem {
    width: $mobileSize;
    height: $mobileSize;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    flex: 0 0 $mobileSize;
    position: relative;
    z-index: 2;
    background: $white;

    @include media('>=tablet') {
        width: $desktopSize;
        height: $desktopSize;
        flex: 0 0 $desktopSize;
    }
}

.Completed {
    background: var(--third-dark);
    box-shadow: 0 5px 10px 0 var(--shadow-color);
}

.Active {
    box-shadow: 0 5px 10px 0 var(--shadow-color);

    &::after {
        width: 50%;
        height: 50%;
        content: '';
        background: var(--third-dark);
        border-radius: 50%;
    }
}

.FutureStep {
    border: 1px solid var(--secondary-dark);
}

.ProgressBar {
    position: absolute;
    border-top: 2px solid var(--third-dark);
    top: 50%;
    left: 0;
    content: '';
    z-index: 1;
}
