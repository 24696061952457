@import 'src/styles/base';

.ModalContent {
    background-color: $white;
    box-shadow: 0 2px 4rem 0 rgba(var(--primary-rgb), 0.6);
    height: 100%;
    outline: 0;
    overflow-y: auto;
    padding: 3.2rem;
    width: 100%;

    @include media('>tablet') {
        border-radius: 3px;
        height: auto;
        left: 50%;
        position: relative;
        top: 50%;
        transform: translate(-50%, -50%);
    }
}

.ModalSizeRegular {
    @include media('>tablet') {
        max-width: 60rem;
    }
}

.ModalSizeWide {
    @include media('>tablet') {
        max-width: 95rem;
    }
}

.ModalOverlay {
    bottom: 0;
    background-color: rgba(var(--primary-rgb), 0.8);
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 100;
    @include media('>tablet') {
        padding: $spacer2;
    }
}

.ModalCloseIcon {
    color: var(--primary);
    cursor: pointer;
    position: absolute;
    right: 1.5rem;
    top: 1.5rem;
    transform: rotateZ(45deg);
}

.ModalTitle {
    @include font-Merriweather-Bold;
    display: block;
    font-size: 1.8rem;
    margin-bottom: 3.2rem;

    @include media('>tablet') {
        color: var(--primary);
        font-size: 2.6rem;
        text-align: center;
    }
}
