@import 'src/styles/base';

.Container {
    display: grid;
}

.Tab {
    @include font-Poppins-Regular;
    color: var(--secondary-dark);
    background: none;
    border: none;
    text-align: center;
    display: block;
    padding: 0.75rem 0;
    font-size: 1.4rem;
    line-height: 1.6rem;
    border-bottom: 1px solid var(--secondary-dark);
    cursor: pointer;
    transition: 0.1s;
    user-select: none;

    &:hover,
    &:focus {
        box-shadow: none;
        outline: none;
    }

    @include media('>=tablet') {
        font-size: 1.7rem;
        line-height: unset;
    }
}

.Active {
    color: var(--third-dark);
    border-bottom: 2px solid var(--third-dark);
}
