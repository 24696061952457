@import 'src/styles/base';

.Container {
    margin: 4rem 0;
    padding: 3.2rem 2rem;

    @include media('<tablet') {
        border-top: none;
        border-bottom: 1.5px solid rgba(var(--secondary-dark-rgb), 0.6);
        margin: 0;
    }
}

.InnerContainer {
    max-width: 57rem;
    margin: auto;
}

.SectionHeader {
    margin-bottom: 3.2rem;
    @include media('>tablet') {
        text-align: center;
    }
}

.Content {
    font-family: $font-secondary;
    font-weight: 300;
    font-style: italic;
    font-size: 1.8rem;
    position: relative;
    line-height: 1.6;

    overflow: hidden;
    transition: height 1s;

    &.NoShowButton {
        height: auto;
        transition: unset;
    }

    &::after {
        box-shadow: 0 1.5rem 2.5rem 2.5rem white;
        content: '';
        position: absolute;
        bottom: 8px;
        left: 0;
        right: 0;
    }
}

.Content.vg {
    font-family: var(--font-primary);
    font-style: normal;

    @include media('>tablet') {
        font-size: 18pt;
        line-height: 1.4;
    }
}

.Expanded,
.Expanded.vg {
    &::after {
        content: none;
    }
}

.Button {
    cursor: pointer;
    margin: auto;
    margin-top: 2rem;
    color: var(--third);
}
