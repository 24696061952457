@import '../../../styles/rwd';

.GalleryDisplay {
    margin-top: 3.2rem;
    margin-bottom: 4rem;

    & vm-loading-screen {
        min-height: unset;
    }
    @include media('<tablet') {
        border-bottom: none;
        border-top: 1.5px solid rgba(var(--secondary-dark-rgb), 0.6);
    }
}

.TimelineDisplay {
    margin: 3.2rem 0;

    @include media('<tablet') {
        border-bottom: none;
        border-top: 1.5px solid rgba(var(--secondary-dark-rgb), 0.6);
    }
}

.LinksContainer {
    display: flex;
    padding: 0 2.5rem;
    justify-content: center;
    flex-direction: column;
    margin-top: 1.6rem;

    @include media('>tablet') {
        justify-content: flex-end;
        padding: 0;
        flex-direction: row;
        margin-top: 0;
    }
}

.Link {
    width: 100%;
    margin-top: 1.6rem;

    @include media('>tablet') {
        width: auto;
        margin-top: 3.2rem;
        margin-left: 1rem;
    }
}
