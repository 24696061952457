@import 'src/styles/base';

.VariantPickerContainer {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-flow: column;
    grid-row-gap: 1.2rem;
    grid-column-gap: 1.6rem;
    margin-bottom: 1rem;
    & * {
        margin: 0;
    }
    @include media('<desktop') {
        grid-template-columns: unset;
        grid-auto-flow: row;
        grid-row-gap: 1.6rem;
        margin-bottom: 2rem;
    }
}

.VariantPickerContainerOneColumnLayout {
    grid-template: unset !important;
    grid-auto-flow: row !important;
}

.VariantPickerItem {
    background-color: var(--background-light);
    border: 1px solid var(--secondary-dark);
    border-radius: 3px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    padding: 1.2rem 1.6rem;
    color: var(--text-dark);
    width: 100%;
    overflow: hidden;

    &:hover {
        box-shadow: 0 2px 7px 0 rgba(var(--secondary-dark-rgb), 0.5);
    }

    &:last-of-type {
        margin-bottom: 0;
    }
}

.Selected {
    background: var(--text-dark);
    color: $white;
}

.VariantPickerItemLabel {
    @include font-Poppins-Regular;
    font-size: 1.25rem;
    white-space: nowrap;
}

.VariantPickerName {
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 5px;
}
