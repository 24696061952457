@import 'src/styles/base';

.Container {
    background-color: var(--secondary-light-gray);
    & .InnerContainer {
        & > div {
            @include section-separator-bar-bottom;
            &:first-of-type {
                @include section-separator-bar-top;
            }
            &:last-of-type {
                border-bottom: 0;
            }
        }
    }
    & .ExpandBox {
        @include hoverable;
        @include centered;
        @include section-separator-bar-top;
        & .ExpandBoxText {
            @include font-Poppins-Medium;
            color: var(--text-dark);
            font-size: 1.4rem;
            padding: $spacer05 0;
        }
    }
}
