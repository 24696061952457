@import 'src/styles/base';

.Container {
    position: relative;
}

.StartAdornment {
    @include font-Poppins-Medium;
    color: var(--text-dark);
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    min-width: 5.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.WithStartAdornment {
    padding-left: 6rem !important;
}

.TextField {
    @include font-Poppins-Regular;
    border: 1px solid var(--secondary-dark);
    border-radius: 3px;
    color: var(--primary);
    font-size: var(--font-size-basic);
    height: 5rem !important; // Overwrite styles from react-phone-input-2
    outline: 0;
    padding: 0 1.5rem;
    width: 100%;

    &::-webkit-input-placeholder {
        color: rgba(var(--text-dark-rgb), 0.8);
        font-size: var(--font-size-basic);
    }
}

.Disabled {
    color: var(--secondary-dark);
    cursor: not-allowed;
}

.Error {
    border-color: var(--error);
}
