@import 'src/styles/base';

.LanguageSelector {
    display: none;
    margin: 0 1rem;
    position: relative;
    @include hoverable;

    @include media('>tablet') {
        @include centered;
    }
}

.ExpandedLanguageSelector {
    opacity: 1 !important;
}

.MobileLanguageSelector {
    display: inline-block;
    margin-right: 1rem;
    position: relative;
    padding-bottom: 1rem;

    @include media('>tablet') {
        display: none;
    }
}

.SelectedLanguage,
.Language {
    align-items: center;
    cursor: pointer;
    display: flex;
    padding: 1rem;
}

.SelectedLanguage {
    min-height: 41.5px;
}

.SelectedLanguageExpanded {
    background-color: $white;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    cursor: default;
}

.LanguageIcon {
    width: 1.75rem;
}

.LanguageName {
    display: block;
    text-transform: uppercase;
    width: 2.25rem;
    text-align: center;
    margin-left: 0.3rem;
}

.Arrow {
    color: var(--secondary-dark);
    width: 2rem;
    padding: 0.175rem;
    margin: 0.275rem;
}
.MobileArrow {
    display: none;
}

.LanguageSelectorDropdown {
    background-color: $white;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    box-shadow: 5px 10px 30px -10px #ddd;
    left: 0;
    position: absolute;
    right: 0;
    top: calc(100% - 1.3rem);

    @include media('>tablet') {
        top: 100%;
    }
}

.LanguageSelectorDropdown.VGModifier {
    top: 100%;
}

.Language {
    transition: background-color 0.3s;

    &:last-of-type {
        border-bottom-left-radius: 3px;
        border-bottom-right-radius: 3px;
    }

    &:hover {
        background-color: var(--background-light);
    }
}
