@import 'src/styles/base';

.ButtonLink {
    @include hoverable;
    @include font-Poppins-Medium;
    display: block;
    text-align: center;
    background: none;
    text-decoration: underline;
    font-size: 1.4rem;
    border: none;
    outline: none;
    color: var(--text-dark);
    &:disabled {
        pointer-events: none;
    }
    &.vg {
        @include font-Merriweather-Medium;
    }
}
