@import 'src/styles/base';

.Button {
    @include hoverable;
    @include font-Poppins-Medium;
    align-items: center;
    border: 1px solid $black;
    border-radius: 3px;
    display: inline-flex;
    font-size: 1.6rem;
    height: 5rem;
    justify-content: space-between;
    outline: 0;
    padding: 0 0 0 2.4rem;
    width: fit-content;
    &.vg {
        @include font-Merriweather-Medium;
    }
}

.ButtonNoIcon {
    padding: 0 2.4rem;
}

.ButtonIconBox {
    align-items: center;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    display: flex;
    height: 5rem;
    justify-content: center;
    margin-left: 2.4rem;
    width: 5rem;
}
