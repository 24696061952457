@import '../../../styles/rwd';

.LoginForm {
    margin: 4rem 2rem 8rem;

    @include media('>tablet') {
        margin: 4rem auto 10rem;
        max-width: 60rem;
    }
}

.ForgotPasswordButton {
    margin: 0 auto;
}
