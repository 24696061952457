@import 'src/styles/base';

.Container {
    @include navigation-button;
    &.sendCondolence {
        @include media('<=450px') {
            display: none;
        }
    }
}
