@import '../../../styles/rwd';

.Navbar {
    left: 50%;
    transform: translateX(-50%);
    z-index: 100;
}

.Navbar.vg {
    font-size: 12pt;
    font-family: 'Raleway';
}

.Layout {
    min-height: calc(100vh - 21rem);
    overflow: hidden;

    @include media('>tablet') {
        min-height: calc(100vh - 14rem);
        padding-top: 7.2rem;
        padding-left: 2rem;
        padding-right: 2rem;
    }

    @include media('>tablet', '<desktop') {
        padding-top: 6.8rem;
    }
}

.Layout.vg {
    @include media('>tablet') {
        padding-top: 12.2rem;
    }
}
