@import 'src/styles/base';

.Container {
    @include centered;
    @include hoverable;
    flex: 2;
    justify-content: center;
    padding: $spacer1 0;
    &:hover {
        @include media('>tablet') {
            & .Icon {
                transform: scale(1.1);
            }
        }
    }
    &:first-of-type {
        flex: 1;
        justify-content: flex-start;
        padding-left: $spacer1;
    }
    &:last-of-type {
        flex: 1;
        justify-content: flex-end;
        padding-right: $spacer1;
    }
    &.Selected {
        opacity: 1 !important;
        &.Like {
            & .Icon {
                @include animation-bounce;
                & svg path {
                    fill: $red;
                    color: $red;
                    opacity: 0.725 !important;
                }
            }
        }

        & .Icon {
            & svg path {
                fill: var(--third-dark);
            }
        }

        & .Label {
            @include font-Poppins-Medium;
        }
    }

    & .Icon {
        @include centered;
        color: var(--third-dark);
    }

    & .Label {
        @include section-sub-header;
        @include font-Poppins-Regular;
        margin-left: $spacer05;
        text-transform: capitalize;
        line-height: 2.4rem;
        &.LabelMobile {
            display: block;
        }
        &.LabelDesktop {
            display: none;
            white-space: nowrap;
        }
        &.Hidden {
            display: none !important;
        }
    }

    & .Count {
        &::before {
            content: ' (';
        }
        &::after {
            content: ')';
        }
    }

    @include media('>desktop') {
        & .Label {
            &.LabelMobile {
                display: none;
            }
            &.LabelDesktop {
                display: block;
            }
        }
    }
}
