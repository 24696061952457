@import 'src/styles/base';

.DeceasedName {
    @include font-Merriweather-Bold;
    text-align: center;
    line-height: 4rem;
    padding: 0 2rem;
    background: var(--secondary);
    border-radius: 3px;
    box-shadow: 0 5px 9px -3px rgba(var(--text-dark-rgb), 0.26);
    font-size: 1.4rem;

    @include media('>tablet') {
        min-width: 57rem;
        font-size: 2.6rem;
        line-height: 5.6rem;
    }
}
