@import 'src/styles/base';

.FlowersCategoryItem {
    cursor: pointer;
    margin-bottom: 3.2rem;
    padding: 1rem;
    transition: background-color 0.3s;
    width: calc(100% / 2);
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &:hover {
        background-color: var(--background-light);
    }

    @include media('>tablet') {
        width: calc(100% / 3);
    }

    @include media('>desktop') {
        width: calc(100% / 4);
    }
}

.FlowersCategoryImageContainer {
    display: flex;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
}

.FlowersCategoryItemImage {
    border: 1px solid rgba(var(--secondary-dark-rgb), 0.6);
    border-radius: 3px;
    height: clamp(15rem, 15vw, 20rem);
    object-fit: contain;
}

.PlaceholderPhoto {
    background: #efefef;
}

.FlowersCategoryItemName,
.FlowersCategoryItemPrice {
    color: var(--text-dark);
    display: block;
}

.FlowersCategoryItemName {
    @include font-Poppins-Medium;
    margin-top: 2rem;

    @include media('>tablet') {
        font-size: 1.6rem;
    }
}

.FlowersCategoryItemPrice {
    font-size: 1.2rem;
    margin-top: 1.3rem;

    @include media('>tablet') {
        font-size: 1.4rem;
    }
}
