@import '../../../styles/colors';

.Message {
    background-color: rgba(var(--success-rgb), 0.25);
    border-radius: 3px;
    box-shadow: 0 2px 7px 0 rgba(var(--secondary-dark-rgb), 0.5);
    color: var(--success);
    font-size: 1.2rem;
    font-weight: 500;
    padding: 0.6rem 1.6rem;
}

.MessageError {
    background-color: rgba(var(--error-rgb), 0.25);
    color: var(--error);
}
