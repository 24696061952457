@import 'src/styles/base';

.Container {
    margin-bottom: $spacer1;
    width: 100%;

    & .InnerContainer {
        @include section-border;
        position: relative;

        & .ContextMenu {
            position: absolute !important;
            right: 1rem;
            top: 1rem;
        }

        & .AuthorBar {
            @include section-separator-bar-bottom;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            column-gap: $spacer1;
            padding: $spacer075 0;
            margin-left: $spacer1;
            margin-right: $spacer1;
            @include media('>desktop') {
                justify-content: flex-start;
            }

            & .Author {
                @include section-header;
                overflow: hidden;
                text-overflow: ellipsis;
                max-width: calc(100% - 6.4rem);
            }
            & .CondolenceDate {
                @include section-sub-header;
                flex-shrink: 0;
                font-size: 1.4rem;
            }
        }

        & .Badges {
            display: flex;
            flex-wrap: wrap;
            padding: $spacer075 $spacer1 0;
            & .CondolenceBadge {
                @include font-Merriweather-Regular;
                align-items: center;
                color: var(--third-dark);
                display: flex;
                flex-direction: row;
                font-size: 1.6rem;
                margin-bottom: $spacer075;
                &:not(last-of-type) {
                    margin-right: $spacer05;
                }
                & .CondolenceBadgeIcon {
                    margin-right: 1rem;
                    flex-shrink: 0;
                }
            }
        }

        & .GalleryContainer {
            user-select: none;
            margin: 0 $spacer1 $spacer075;
            &.WithBadge {
                @include section-separator-bar-top;
                padding-top: $spacer075;
            }
            & .GalleryInnerContainer {
                @include section-border;
                margin: auto;
                box-shadow: 0 2px 7px 0 rgba(var(--secondary-dark-rgb), 0.5);
            }
        }

        & .Content {
            @include section-paragraph;
            padding: 0 $spacer1 $spacer075 $spacer1;
        }

        & .SocialBar {
            background-color: var(--secondary-light-gray);
            border-top: 1px solid rgba(var(--secondary-dark-rgb), 0.6);
            display: flex;
            align-items: center;
            user-select: none;
        }
    }

    @include media('<tablet') {
        & .InnerContainer {
            border-left: 0;
            border-right: 0;
        }
    }

    @include media('>tablet') {
        width: 50%;
        padding-left: $spacer05;
        padding-right: $spacer05;
    }

    @include media('>desktop') {
        & .InnerContainer {
            & .AuthorBar {
                padding: $spacer1 0;
                flex-direction: row;
                align-items: center;
                & .CondolenceDate {
                    font-size: 1.6rem;
                }
            }
            & .Badges {
                padding: $spacer1 $spacer1 0;
                & .CondolenceBadge {
                    margin-bottom: $spacer1;
                }
            }
            & .Content {
                padding: 0 $spacer1 $spacer1 $spacer1;
            }
        }
    }
}
