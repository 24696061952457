@import './font';

@mixin section-header {
    @include font-Poppins-SemiBold;
    color: var(--third-dark);
    font-size: 1.8rem;
    line-height: 2.7rem;
}

@mixin section-sub-header {
    @include font-Poppins-SemiBold;
    color: var(--text-dark);
    font-size: 1.6rem;
    line-height: 2.5rem;
}

@mixin section-sub-header-secondary {
    @include font-Poppins-Medium;
    color: var(--text-dark);
    font-size: 1.8rem;
    line-height: 2.7rem;
}

@mixin section-paragraph {
    @include font-Poppins-Regular;
    color: var(--text-dark);
    font-size: 1.6rem;
    line-height: 3.2rem;
    opacity: 0.8;
}
