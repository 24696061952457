@import 'src/styles/base';

.TextField.PhoneInput.open {
    @include font-Poppins-Regular;
    border: 1px solid var(--secondary-dark);
    border-radius: 3px;
    color: var(--primary);
    font-size: 1.6rem;
    height: 5rem;
    outline: 0;
    width: 100%;
    box-shadow: none;

    &::-webkit-input-placeholder {
        color: var(--text-dark);
        font-size: 1.4rem;
    }

    &:focus {
        border: 1px solid var(--secondary-dark);
        box-shadow: none;
    }
}

.Wrapper {
}

.Container {
    display: flex;
    align-items: center;
}

.InputContainer {
    width: 100%;
}

.Error {
    border-color: var(--error) !important;
}
