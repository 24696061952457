@import 'src/styles/base';

.FileUploader {
    align-items: center;
    background-color: rgba(var(--secondary-dark-rgb), 0.1);
    border: 1px dashed rgba(var(--secondary-dark-rgb), 0.6);
    border-radius: 3px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    height: 17.7rem;
    justify-content: center;
    transition: background-color 0.3s;
    width: 17.7rem;

    &:hover {
        background-color: rgba(var(--secondary-dark-rgb), 0.3);
    }
}

.FileUploaderIconContainer {
    align-items: center;
    border: 1.5px solid rgba(var(--text-dark-rgb), 0.6);
    border-radius: 50%;
    display: flex;
    height: 3.4rem;
    justify-content: center;
    width: 3.4rem;
}

.FileUploaderIcon {
    color: rgba(var(--text-dark-rgb), 0.6);
}

.FileUploaderLabel {
    @include font-Poppins-Medium;
    color: rgba(var(--text-dark-rgb), 0.6);
    margin-top: 0.8rem;
}

.FileUploaderPreview {
    height: 100%;
    transition: opacity 0.3s;
    width: 100%;
    object-fit: contain;
    object-position: center;

    &:hover {
        opacity: 0.7;
    }
}
