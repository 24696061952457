@import 'src/styles/base';

.Modal {
  height: auto;
  max-height: 100vh;
  border: 1px solid rgba(var(--secondary-dark-rgb), 0.6);
}

.ModalOverlay {
  background: rgba($white, 0.85);
}