@import 'src/styles/base';

.FlowerRow {
    align-items: center;
    border-bottom: 1px solid rgba(var(--secondary-dark-rgb), 0.6);
    display: flex;
    justify-content: space-between;
    padding: $spacer05 0;
    &:last-of-type {
        border-bottom: 0;
    }
    &.vg {
        & .FlowerBox {
            & .FlowerName {
                font-size: 1.6rem;
            }
        }
    }
}

.FlowerBox {
    align-items: center;
    display: flex;
    width: 50%;
    cursor: pointer;
    border: none;
    padding: 0;
}

.FlowerBox.BasketIcon {
    width: 100%;
}

.FlowerName {
    @include font-Poppins-Regular;
    font-size: 1.4rem;
    line-height: 2.2rem;
    color: var(--text-dark);
    word-break: keep-all;
    text-align: start;
    font-weight: 600;
}

.FlowerImage {
    border: 1px solid rgba(var(--secondary-dark-rgb), 0.6);
    border-radius: 50%;
    height: 6rem;
    margin-right: 1.5rem;
    width: 6rem;
    flex-shrink: 0;
    object-fit: cover;
    object-position: center;
}

.FlowerImage.NoSrc {
    border: none;
}

.OrderButton {
    white-space: nowrap;
    margin-left: $spacer05;
    &.SmallerButton {
        padding-left: 1.6rem;
        font-size: 1.4rem;
        & span {
            margin-left: 1.6rem;
        }
    }
    &.BasketIcon {
        margin-left: $spacer05;
        padding-left: 1.2rem;
        padding-right: 1.2rem;
    }
}
