@import 'src/styles/colors';
@import 'src/styles/rwd';
@import 'src/styles/mixins/common';
@import 'src/styles/typography/section';

@mixin navigation-button {
    @include hoverable;
    @include centered;
    flex: 1;
    align-items: stretch;
    &:first-child {
        & .InnerContainer {
            margin-left: 0.4rem;
        }
    }
    &:last-child {
        & .InnerContainer {
            margin-right: 0.4rem;
        }
    }
    &.vg {
        & .InnerContainer {
            & .Text {
                font-family: unset;
            }
        }
    }
    &.Active {
        opacity: 1 !important;

        & .InnerContainer {
            background-color: var(--third-dark) !important;

            & .Icon,
            & .Text {
                color: $white !important;
            }
        }
    }

    & .InnerContainer {
        @include centered;
        @include rounded;
        flex: 1;
        flex-direction: column;
        margin: 0.8rem 0.2rem;
        padding: 1.1rem 0.75rem;
        transition: background-color $faster;

        & .Icon,
        & .Text {
            transition: color $faster;
        }

        & .Icon {
            color: $white;
            margin-bottom: 1.1rem;
            min-height: 16px;
            min-width: 16px;
            &.Promoted {
                color: var(--third-dark);
            }
            @include media('<tablet') {
                &.DonationIconFix {
                    margin-top: -0.42rem;
                }
            }
        }

        & .Text {
            @include font-Poppins-SemiBold;
            letter-spacing: 0.6px;
            text-decoration: none;
            text-transform: uppercase;
            color: $white;
            font-size: 0.915rem;
            line-height: 0.915rem;
            white-space: nowrap;

            & .DesktopName {
                display: none;
            }
            & .MobileName {
                display: block;
            }
        }
    }

    @include media('>tablet') {
        & .InnerContainer {
            flex-direction: row;
            padding: 1.75rem 1.25rem;
            margin: 0.8rem !important;

            & .Icon {
                margin-bottom: 0;
                margin-right: 0.8rem;
            }

            & .Text {
                font-size: 1.4rem;
                line-height: unset;
                & .DesktopName {
                    display: block;
                }
                & .MobileName {
                    display: none;
                }
            }
        }
    }

    @include media('>tablet', '<desktop') {
        &:last-child {
            &:not(.vg) {
                & .InnerContainer {
                    margin-right: 0 !important;
                }
            }
        }
        & .InnerContainer {
            margin: 0.8rem 0.4rem;
            padding: 1.75rem 0.75rem;

            & .Text {
                font-size: 1.2rem;
            }
        }
    }
}
