@import 'src/styles/rwd';

.Form {
    display: grid;
    grid-gap: 1rem;

    @include media('>=tablet') {
        grid-template-columns: 3fr 3fr 3fr;
    }
}
