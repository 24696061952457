@import 'src/styles/base';

.Container {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    row-gap: $spacer025;
    padding: $spacer05 $spacer1;

    & .AuthorBar {
        display: flex;
        align-items: center;
        justify-content: space-between;
        column-gap: $spacer05;
        & .FirstColumn {
            flex: 1;
            display: flex;
            flex-direction: column;
            row-gap: $spacer025 / 2;
            max-width: calc(100% - 6.4rem);
            & .Author {
                @include section-header;
                font-size: 1.6rem;
                line-height: 2.5rem;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            & .CondolenceDate {
                @include section-sub-header;
                font-size: 1.4rem;
                line-height: 2.1rem;
            }
        }
        & .SecondColumn {
            & > :first-child {
                padding: $spacer05;
                margin: -$spacer05;
                & > :nth-child(2),
                :nth-child(3) {
                    text-align: end;
                    margin-left: 0.6rem;
                    width: 2rem;
                }
                & > :nth-child(3) {
                    text-align: unset;
                }
            }
        }
    }
    & .Content {
        @include section-paragraph;
        font-size: 1.4rem;
        line-height: 2.8rem;
    }

    @include media('>desktop') {
        row-gap: $spacer05;
        padding: $spacer075 $spacer1;
        & .AuthorBar {
            & .FirstColumn {
                flex-direction: row;
                align-items: center;
                & .CondolenceDate {
                    margin-left: $spacer05;
                    flex-shrink: 0;
                }
            }
        }
    }
}
