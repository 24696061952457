@import 'src/styles/base';

.ImageContainer {
    position: relative;
    padding: 0.5rem;
    height: 33vw;
    cursor: pointer;
    & .ImageInnerContainer {
        height: 100%;
    }
    & .Image {
        height: 100%;
        max-width: 100%;
        object-fit: cover;
        object-position: center;
        cursor: pointer;
        margin-right: auto;
        margin-left: auto;
    }
    @include media('>tablet') {
        height: 20rem;
    }
}

.DarkBackgroundFilter {
    filter: brightness(0.5);
}

.PlayIcon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    filter: drop-shadow(0 0 1px var(--text-dark));
    z-index: 1;
    cursor: pointer;
    svg {
        width: 6rem;
        height: 6rem;
    }
}

.ModalContainer {
    width: 100%;
    height: 100%;
    background: rgba($white, 0.97);
    display: flex;
    align-items: center;
    justify-content: center;
}

.TitleContainer,
.DescriptionContainer {
    padding: 0 1rem 1rem;

    @include media('>tablet') {
        padding: 0 0.5rem 0.5rem;
    }
}

.TitleContainer {
    @include font-Poppins-Medium;
}
