@import 'src/styles/base';

.Button {
    @include hoverable;
    @include font-Poppins-Medium;
    background-color: rgba(var(--secondary-dark-rgb), 0.2);
    border: 1px solid var(--secondary-dark);
    border-radius: 3px;
    color: var(--text-dark);
    font-size: 1.15rem;
    height: 2.8rem;
    outline: 0;
    padding: 0 1.6rem;
    transition: background-color 0.3s;
}

.ButtonDisabled {
    cursor: not-allowed;
    opacity: 0.5 !important;

    &:hover {
        background-color: rgba(var(--secondary-dark-rgb), 0.2);
    }
}
