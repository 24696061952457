@import 'src/styles/base';

.FieldsContainer {
    display: flex;
    justify-content: space-between;
    margin: $spacer1 0 $spacer05;
}

.FieldWrapper {
    width: 100%;
}

.AuthorInputContainer {
    @include media('>tablet') {
        max-width: 60%;
    }
}

.EnhancementsContainer {
    display: none;

    @include media('>tablet') {
        display: flex;
    }
}

.MobileEnhancementsContainer {
    display: flex;
    justify-content: space-between;
    margin: 1rem 0 2rem;

    @include media('>tablet') {
        display: none;
    }
}

.Checkbox {
    @include media('>tablet') {
        margin-right: 5rem;
    }
}

.ButtonContainer {
    display: flex;
    margin-top: 1rem;

    @include media('>tablet') {
        justify-content: flex-end;
    }
}

.PublishButton {
    width: 100%;

    @include media('>tablet') {
        width: fit-content;
    }
}
