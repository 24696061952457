@import '../../../styles/colors';

.Spinner {
    animation: loader-1-1 4.8s linear infinite;
    height: 26px;
    width: 26px;

    span {
        animation: loader-1-2 1.2s linear infinite;
        bottom: 0;
        border: 3px solid rgba($white, 0.85);
        border-radius: 50%;
        clip: rect(0, 26px, 26px, 10px);
        display: block;
        height: 26px;
        left: 0;
        margin: auto;
        position: absolute;
        right: 0;
        top: 0;
        width: 26px;
    }
}

@keyframes loader-1-1 {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@keyframes loader-1-2 {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
