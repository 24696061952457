@import '../../../styles/colors';

.Line {
    border: 0;
    height: 1px;
    width: 100%;
    background-image: linear-gradient(
        to right,
        rgba(0, 0, 0, 0),
        rgba(var(--third-dark-rgb), 0.79),
        rgba(0, 0, 0, 0)
    );
}
