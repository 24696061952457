@import 'src/styles/base';

.CheckboxContainer {
    @include hoverable;
    align-items: center;
    cursor: pointer;
    display: flex;
    margin: 0 1rem;
}

.Checkbox {
    appearance: none;
    border: 1px solid var(--secondary-dark);
    border-radius: 1px;
    margin: 0;
    outline: 0;
    height: 2.5rem;
    width: 2.5rem;
    flex-shrink: 0;

    &:checked:after {
        display: block;
    }

    &:after {
        border: solid var(--third-dark);
        border-width: 0 2px 2px 0;
        content: '';
        display: none;
        height: 60%;
        left: 37%;
        position: relative;
        top: 8%;
        transform: rotate(45deg);
        width: 20%;
    }

    @include media('>tablet') {
        height: 2rem;
        width: 2rem;
    }
}

.CheckboxLabel {
    color: var(--text-dark);
    font-size: 1.6rem;
    margin-left: 2rem;

    @include media('>tablet') {
        margin-left: 1.5rem;
    }
}

.CheckboxIcon {
    margin-left: 1rem;
}
