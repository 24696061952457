@import '../../../styles/rwd';

.FlowersCategoryItems {
    display: flex;
    flex-wrap: wrap;
}

.BackButton {
    margin: 0 0 3rem 1rem;

    @include media('>tablet') {
        margin-bottom: 0;
    }
}

.Description {
    margin-bottom: 1rem;
    text-align: center;
}
