@import '../../../styles/colors';

.PrimaryButton {
    background-color: var(--third-dark);
    border: 0;
    color: $white;
}

.ButtonDisabled {
    pointer-events: none;
    opacity: 0.5;
}

.PrimaryButtonIconBox {
    background-color: var(--third);
}
