@import 'src/styles/base';

.FlowerType {
    display: flex;
    padding: 1rem;
    flex-direction: column;

    @include media('>tablet') {
        max-width: 100%;
        margin: 0;
    }
}

.FlowerTypeBox {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.FlowerTypeImageContainer {
    border-radius: 3px;
    height: clamp(15rem, 15vw, 20rem);
    min-width: initial;
    position: relative;
    width: 100%;
    display: flex;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
}

.FlowerTypeBanner {
    background-color: var(--third-dark);
    border-radius: 3px;
    color: $white;
    font-size: 1rem;
    font-weight: 600;
    text-align: center;
    padding: 0.5rem 1rem;
    position: absolute;
    width: 100%;

    @include media('>phone') {
        font-size: 1.2rem;
    }
}

.FlowerTypeImageWrapper {
    height: 100%;
    width: 100%;
    max-width: 100%;
    cursor: pointer;
}

.FlowerTypeImage {
    border: 1px solid rgba(var(--secondary-dark-rgb), 0.6);
    border-radius: 3px;
    height: 100%;
    width: 100%;
    max-width: 100%;
    object-fit: contain;
}

.FlowerTypeImage.NoSrc {
    border: none;
}

.PlaceholderPhoto {
    background: #efefef;
}

.FlowerTypeName {
    @include font-Poppins-Medium;
    display: flex;
    align-items: center;
    color: var(--text-dark);
    height: 7rem;

    & span {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2; /* number of lines to show */
        line-clamp: 2;
        -webkit-box-orient: vertical;
        line-height: 2.5rem;
    }

    @include media('>phone') {
        font-size: 1.6rem;
    }
}

.FlowerTypeVariants {
    margin: 0 auto;

    @include media('>tablet') {
        width: 100%;
    }
}
