@import 'src/styles/base';

.GalleryExpander {
    cursor: pointer;
    height: 33vw;
    padding: 0.5rem;
    position: relative;

    @include media('>tablet') {
        height: 20rem;
    }

    &:hover {
        .Overlay {
            background-color: var(--third-dark);
        }

        .Label {
            display: none;
        }

        .LabelOnHover {
            display: block;
        }
    }
}

.GalleryBackground {
    object-fit: cover;
    object-position: center;
    height: 100%;
    width: 100%;
}

.Overlay {
    background-color: rgba($black, 0.6);
    height: calc(100% - 1rem);
    position: absolute;
    top: 0.5rem;
    transition: background-color 0.3s;
    width: calc(100% - 1rem);

    @include media('>tablet') {
        height: calc(100% - 1rem);
        top: 0.5rem;
        width: calc(100% - 1rem);
    }
}

.Label,
.LabelOnHover {
    @include font-Poppins-Medium;
    color: $white;
    font-size: 1.6rem;
    left: 50%;
    overflow: hidden;
    position: absolute;
    text-align: center;
    top: 50%;
    transform: translate(-50%, -50%);
}

.LabelOnHover {
    display: none;
}
