@import 'src/styles/base';

.Gallery {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding: 4rem 2rem 0;

    @include media('>tablet') {
        grid-template-columns: repeat(4, 1fr);
        justify-content: start;
        padding: 0.5rem;
    }
    @include media('>desktop') {
        grid-template-columns: repeat(5, 1fr);
        justify-content: start;
    }
}

.SectionHeader {
    padding-left: 2.5rem;

    @include media('>tablet') {
        padding-left: 0;
    }
}

.SectionActions {
    padding: 0 2.5rem;

    @include media('>tablet') {
        padding: 0;
        margin-top: 0;
    }
}

.SectionActionButton {
    margin-top: 1.6rem;
    width: 100%;

    @include media('>tablet') {
        margin-top: 0;
        width: auto;
    }

    &:first-of-type {
        @include media('>tablet') {
            margin-right: 2.4rem;
        }
    }
}

.InfoMessage {
    @include font-Poppins-Medium;
    color: var(--text-dark);
    font-size: 1.6rem;
    margin-bottom: 1rem;
    text-align: center;

    @include media('>tablet') {
        margin-bottom: 2rem;
        text-align: right;
    }
}
