@import '../../../styles/base';

.Section {
    border-bottom: 1px solid rgba(var(--secondary-dark-rgb), 0.6);
    border-top: 1px solid rgba(var(--secondary-dark-rgb), 0.6);
    padding: 3.2rem 0;

    @include media('>tablet') {
        border: 1px solid rgba(var(--secondary-dark-rgb), 0.6);
        border-radius: 3px;
        padding: 3.2rem;
    }
}

.SectionContent {
}

.CenterHeader {
    text-align: center;
}

.SectionHeaderContainer {
    margin-bottom: 1.6rem;

    @include media('>tablet') {
        margin-bottom: 3.2rem;
    }

    @include media('>tablet') {
        align-items: center;
        display: flex;
        justify-content: space-between;
    }
}
