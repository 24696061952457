@import 'src/styles/base';

.Button {
    @include hoverable;
    @include font-Poppins-Medium;
    align-items: center;
    background-color: $white;
    border: 1px solid var(--secondary-dark);
    border-radius: 3px;
    color: var(--text-dark);
    display: flex;
    font-size: 1.4rem;
    font-weight: 500;
    outline: 0;
    padding: 0.5rem;
    margin-right: 1rem;
    margin-bottom: 1rem;
    &.vg {
        @include font-Merriweather-Medium;
    }

    @include media('>tablet') {
        padding: 0.25rem 1.4rem;
        margin-bottom: 0;
    }
}

.Icon {
    margin-right: 0;
    overflow: visible;
    & path {
        fill: var(--text-dark);
        stroke: var(--text-dark);
    }
    @include media('>tablet') {
        margin-right: 1rem;
    }
}

.Label {
    display: none;

    @include media('>tablet') {
        display: inline-block;
    }
}
