@import '../../../styles/colors';

.Label {
    display: inline-block;
    cursor: pointer;
}

.Radio {
    width: 2rem;
    height: 2rem;
    background: var(--secondary);
    border-radius: 50%;
    margin-right: 1rem;
}

.Active {
    border: 1px solid var(--secondary);
    background: $white;
    position: relative;

    &::after {
        content: '';
        background: var(--third-dark);
        width: 1rem;
        height: 1rem;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 50%;
        position: absolute;
    }
}

.Wrapper {
    display: flex;
    align-items: center;
}
