$white: #fff;
$black: #000;
$red: red;
$bismark: #426783;
$bismark-light: #788a9c;
$rhino: #314165;
$tonys-pink: #e4a47d;
$manhattan: #f2b895;
$jungle-mist: #c0cfda;
$athens-gray: #f2f5f7;
$secondary-light-gray: rgba(242, 245, 247, 0.4);
$blue-bayoux: #506473;
$mystic: #e6ecf0;
$alto: #dddddd;
$valencia: #d74a3a;
$malachite: #00bb33;
$shadow-color: rgba(#e4a47d, 0.45);
$mist-opacity: rgba(#c0cfda, 0.8);
$common-blue-dark: #24b0e7;
$common-blue: #4cccff;
$beige: #453F41;
$beige-mist: #FBF0DC;
$common-yellow: #CEA56D;
$common-yellow-dark: #91683B;
$terracotta: #424750;