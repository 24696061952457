@import '../../../styles/rwd';
@import '../../../styles/typography';
@import '../../../styles/vg';

.Container {
    max-width: 38rem;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 4rem;
    text-align: center;
    margin-bottom: 4rem;
}

/**
 * TODO: Should we set any typography here or leave it to WYSIWYG? Probably it will be overwritten
 */
.Content {
    font-family: $font-secondary;
    font-weight: 300;
    font-style: italic;
    font-size: 1.8rem;
    position: relative;
    line-height: 1.6;

    max-height: 11.5rem;
    overflow: hidden;

    &::after {
        box-shadow: 0 1.5rem 2rem 2.5rem white;
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
    }
    & * div {
        text-align: center;
    }
}

.Content.vg {
    font-family: var(--font-primary);
    font-style: normal;

    @include media('>tablet') {
        font-size: 18pt;
        line-height: 1.4;
        max-height: 13rem;
    }
}

.Expanded,
.vg.Expanded {
    max-height: none;

    &::after {
        content: none;
    }
}

.Button {
    cursor: pointer;
    margin-top: 2rem;
}

.Button.vg {
    font-family: $vg-font-secondary;
    font-size: 11pt;
    font-weight: 100;
    text-transform: uppercase;
}
