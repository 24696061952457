@import '../variables/transitions';

@keyframes slide-up {
    from {
        transform: translateY(100%);
    }
    to {
        transform: translateY(0%);
    }
}

@keyframes slide-down {
    from {
        transform: translateY(0%);
    }
    to {
        transform: translateY(100%);
    }
}

@keyframes bounce-mobile {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.1);
    }
    100% {
        transform: scale(1);
    }
}

@keyframes bounce-desktop {
    0% {
        transform: scale(1.1);
    }
    50% {
        transform: scale(1.2);
    }
    100% {
        transform: scale(1.1);
    }
}

@mixin animation-duration-slow {
    animation-duration: $slow;
}

@mixin animation-slide-up {
    @include animation-duration-slow;
    animation-name: slide-up;
}

@mixin animation-slide-down {
    @include animation-duration-slow;
    animation-name: slide-down;
}

@mixin animation-bounce {
    animation: bounce-mobile $fast cubic-bezier(0.5, 1.75, 0.75, 1.25);
    @include media('>tablet') {
        animation-name: bounce-desktop;
    }
}
