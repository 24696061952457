@import 'src/styles/base';

.Item {
    border-top: 1px solid rgba(var(--secondary-dark-rgb), 0.6);
    display: flex;
    padding: 2.2rem 0;
    & > div {
        flex: 1;
        &:first-of-type {
            flex: 0.75;
        }
    }
}

.Cell {
    align-items: center;
    display: flex;
    justify-content: center;
    text-align: center;
}

.FlowersCategoryContainer {
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.FlowersCategory {
    @include font-Poppins-Medium;
    color: var(--text-dark);
    display: block;
    font-size: 1.6rem;
    margin-bottom: 0.8rem;
}

.AdjustmentsInfo {
    color: var(--text-dark);
    display: block;
    margin-bottom: 0.8rem;
}

.BandText {
    color: var(--text-dark);
    display: block;
    font-style: italic;
    &:not(:last-of-type) {
        margin-bottom: 0.8rem;
    }
}

.VariantPicker {
    width: 80%;
}

.TotalPrice {
    color: var(--text-dark);
    font-weight: 600;
    user-select: none;
}

.IconWrapper {
    align-items: center;
    background-color: var(--secondary-dark);
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    padding: 0.8rem;
    transition: background-color 0.3s;

    &:hover {
        background-color: var(--text-dark);
    }

    &:first-of-type {
        margin-right: 0.8rem;
    }
}

.Icon {
    color: $white;
}

.VariantPhoto {
    height: 7rem;
    width: 100%;
    object-fit: contain;
    padding-right: 15px;
}
