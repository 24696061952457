@import '../../../styles/colors';

.Container {
    align-items: center;
    display: flex;
    flex-direction: column;
}

.Subheader {
    color: var(--third-dark);
    display: block;
    font-size: 1.8rem;
    font-weight: 600;
}

.ShareButton {
    margin: 3.2rem 0;
}
