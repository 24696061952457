@import 'src/styles/base';

.ContextMenu {
    &:not(.ContextMenuOpen) {
        @include hoverable;
    }
    border-radius: 3px;
    cursor: pointer;
    display: inline-flex;
    padding: 1rem;
    position: relative;
}

.ContextMenuOpen {
    background-color: var(--text-dark);
    box-shadow: 0 2px 8px 0 var(--secondary);
    cursor: initial;
    z-index: 1;

    .ContextIcon {
        color: $white;
        position: absolute;
        right: 1rem;
        top: 1rem;
    }
}

.ContextIcon {
    color: var(--text-dark);
}

.ContextMenuItems {
    padding-top: 1.6rem;
}

.ContextMenuItem {
    @include hoverable;
    align-items: center;
    border-radius: 3px;
    cursor: pointer;
    display: flex;
    padding: 0.6rem 1.6rem 0.6rem 0.6rem;
    transition: background-color 0.3s;

    &:last-of-type {
        margin-bottom: 0;
    }
}

.ContextMenuItemIcon {
    color: $white;
    margin-right: 1rem;
    width: 1.4rem;
}

.ContextMenuItemLabel {
    color: $white;
    font-weight: 500;
}
