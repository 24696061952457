.InputsContainer {
    margin: 0 auto;
    max-width: 39rem;
    width: 100%;
    margin-top: 3rem;
}

.Control {
    display: block;
    margin-bottom: 2rem;
}

.RadioGroup {
    color: var(--text-dark);
    font-size: 1.6rem;
    margin-left: 1rem;
}

.Radio {
    display: block;
    margin: 1rem 0;
}

.SubmitButton {
    display: flex;
    margin: 3rem auto 2rem;
    max-width: 27rem;
    width: 100%;
}
