@import 'src/styles/base';

.ListRow {
    display: flex;
    color: var(--text-dark);
    margin-bottom: 1.5rem;
    &:first-of-type {
        margin-top: 1.6rem;
    }
    &.ListRowCentered {
        align-items: center;
    }
}

.ListRowWithMap {
    display: flex;
    flex-direction: column;

    & .ListRowContainer {
        & :last-child {
            margin: 0;
        }
    }

    & .MapContainer {
        height: 14.5rem;
        width: unset;
        margin: 1rem 1rem 1.5rem 5rem;
        padding: 0.6rem;
        box-shadow: 0 2px 10px rgba(var(--secondary-dark-rgb), 0.5);
        border-radius: 3px;
        & .MapInnerContainer {
            height: 100%;
        }
        & .MapImageContainer {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            width: 100%;
            position: relative;
            overflow: hidden;
            &:after {
                content: attr(data-translation);
                opacity: 0;
                transition: opacity $faster;
                position: absolute;
                top: 0;
                height: 100%;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                background: rgba($black, 0.33);
                text-align: center;
                font-weight: 500;
                font-size: clamp(1.8rem, 1.8rem, 2rem);
                color: $white;
                text-transform: capitalize;
                cursor: pointer;
            }
            &:hover {
                &:after {
                    opacity: 1;
                }
            }
        }
    }

    @include media('>desktop') {
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 1rem;
        & .ListRowContainer {
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            padding-right: 1.5rem;
            & .ListRow {
                margin: 0;
                & .RowContent {
                    text-align: start;
                }
            }
        }
        & .MapContainer {
            width: 25rem;
            min-width: 22rem;
            margin: 0.75rem;
        }
    }
    @include media('>1100px') {
        & .MapContainer {
            min-width: 25rem;
        }
    }
}

.clickableListRow {
    @include hoverable;
    color: var(--third-dark);
}

.IconContainer {
    width: 3.2rem;
    margin-right: 2rem;
    background: rgba(var(--secondary-dark-rgb), 0.2);
    border-radius: 50%;
    height: 3.2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 0 0 3.2rem;
}

.Icon {
    width: 2rem;
    height: 2rem;
    color: var(--text-dark);
}

.RowContent {
    @include font-Poppins-Regular;
    margin: 0.5rem 0;
    align-self: center;
    word-break: break-word;
    width: 100%;
}

.Link {
    color: var(--third-dark);
    &:hover,
    &:focus {
        text-decoration: none;
    }
}

.LeafletLink {
    display: block;
    margin-right: 0.5rem;
    width: max-content;
}

.ActionLinkButton {
    margin: 1rem 0;
    width: 100%;
    &:disabled {
        cursor: not-allowed;
    }

    @include media('<=450px') {
        justify-content: center;
        padding: revert;
        span {
            display: none;
        }
    }

    @include media('>tablet') {
        width: fit-content;
    }
}

.Divider {
    border-top: 1px solid rgba(var(--secondary-dark-rgb), 0.6);
    padding-top: 3.2rem;
}

.DowloadsPreview {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: 130px;
    transition: width 1s, height 2s;
    overflow-y: hidden;
    margin: 0 auto;
    border: none;
    color: var(--primary);
    align-items: stretch;
    outline: 0;
    position: relative;
}

.Fade {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 50px;
    z-index: 2;
    background: rgba(255, 255, 255, 0)
        linear-gradient(
            to bottom,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 0.8) 80%
        )
        repeat scroll 0 0;

    &.expanded {
        display: none;
    }
}

.EventsContainer {
    width: 100%;
}

.EventsContainer.TwoColumn {
    display: grid;
    grid-template-columns: 1fr;

    @include media('>tablet') {
        column-gap: 10rem;
        grid-template-columns: 1fr 1fr;
    }
}

.Downloads {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 1rem;
}

div.DownloadsContainer {
    width: 100%;
    height: auto;
    padding: 1rem;
    margin: 0.5rem auto;
    padding-bottom: 6.5rem;
    justify-content: unset;

    a {
        bottom: 5rem;
        right: 1rem;
    }
}

img.DownloadsImage {
    height: auto;
    width: 90%;
    max-width: 300px;
}

.CollapseButton {
    margin: 0;
}

.DNInnerContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.DNPreviewContainer {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    cursor: pointer;
    border: none;
    outline: 0;
}

.DNList {
    padding: 0.5rem;
    align-self: flex-start;
}

.VideoStreamIconBox {
    padding: 1rem;
    color: $white;
    & svg {
        height: 21px;
    }
}

.Tooltip {
    font-family: var(--font-primary) !important;
    font-weight: 500 !important;
    font-size: 1.1rem !important;
}

.TooltipInnerContainer {
    cursor: not-allowed;
    width: fit-content;
}
