@import 'src/styles/base';

.Section {
    padding-left: 2rem;
    padding-right: 2rem;
    padding-bottom: 0;
    margin-bottom: 4rem;

    @include media('<tablet') {
        border: none;
    }
}

.CondolenceFormOverTheList {
    margin: 0 $spacer3;
    @include media('<tablet') {
        margin: 0 $spacer05;
    }
}

.SectionEmpty {
    padding-bottom: $spacer1;
}

.SectionHeader {
    @include media('>tablet') {
        text-align: center;
    }
}

.ButtonBox {
    align-items: center;
    justify-content: center;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    margin: $spacer1 $spacer05;
    column-gap: 2rem;
    row-gap: 1rem;
}

.PublishText {
    font-size: 1.2rem;
    margin-top: $spacer05;
}

.CondolencesButton {
    width: 100%;
    @include media('>tablet') {
        width: fit-content;
        margin-top: 0;
    }
}

.SubHeader {
    text-align: center;
    @include section-sub-header-secondary;
    & .DeceasedName {
        white-space: nowrap;
    }
}
