@import 'src/styles/rwd';

.Form {
    display: flex;
    flex-direction: column;
    margin: 2rem 0;

    @include media('>tablet') {
        flex-direction: row;
    }
}

.FieldWrapper {
    margin-bottom: 1rem;
    width: 100%;

    &:last-of-type {
        margin-bottom: 0;
    }

    @include media('>tablet') {
        margin-bottom: 0;
        margin-right: 1rem;

        &:last-of-type {
            margin-right: 0;
        }
    }
}
