@import '../../../styles/rwd';

.EventAttendanceForm {
    margin: 4rem 2rem 8rem;

    @include media('>tablet') {
        margin: 4rem auto 10rem;
        max-width: 60rem;
    }
}

.RegistrationCompleted {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 4rem;
}

.RegistrationCompletedRow {
    margin-bottom: 3rem;
}

.PageText {
    margin: 3rem;
    text-align: center;
    font-size: 16px;
}
