@import '../../../../styles/colors';

.FacebookButton {
    background-color: var(--primary);
    border: 0;
    color: $white;
}

.FacebookButtonIconBox {
    background-color: var(--text-dark);
}

.FacebookButtonIconBox.vg {
    background-color: var(--third);
}
